// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import { HttpHeaders } from "@angular/common/http";

export const environment = {
    production: false,
    hmr: false,

    // TEST
    //baseUrl: 'https://core-b-test.nts-blueocean.com',
    
    // DEV
    baseUrl: 'https://dev-core-b.nts-blueocean.com',
    
    appConfig: 'appconfig.json',
    
    //apiBaseUrl: 'https://bo-dev.nts-api.com/api/',
    //apiBaseUrl: 'https://bo-dev.nts-api.com/pristine/api/',
    apiBaseUrl: 'https://bo-dev.nts-api.com/dev/api/',
    //apiBaseUrl: 'https://localhost:44391/api/',
    
    apiKey: '081a7148-eb61-4e9e-9d51-e54de3bc9ba2',
    outboundMessageApiBaseUrl: 'https://bo-dev.nts-api.com/pm/',
    odataBaseUrl: 'https://odata.nts-blueocean.com:48989/api.rsc/',
    odataQueryBaseUrl: 'https://odata.nts-blueocean.com:48989/query.rsc/MSSQL01.BODATA',
    odataKey: '6g4B1n8a7F7u0y0F1i4g',
    nqaRedirectUrl: 'https://nqa.nts-blueocean.com',
    customerRedirectUrl: 'https://customer.nts-blueocean.com',
    auditorRedirectUrl: 'https://auditor.nts-blueocean.com',

    headers: new HttpHeaders().set('Content-Type', 'application/json')
        .set('ApiKey', '081a7148-eb61-4e9e-9d51-e54de3bc9ba2'),
    //.set('Ocp-Apim-Trace', 'true')
    //.set('Ocp-Apim-Subscription-Key', '57763804cb5c4480892c67387a514443')

    outboundMessageHeaders: new HttpHeaders().set('Ocp-Apim-Trace', 'true')
        .set('Content-Type', 'application/json')
        .set('Ocp-Apim-Subscription-Key', '47c49a2011094d34b517fbc9db58d4f0'),

    dataApiHeaders: new HttpHeaders().set('Ocp-Apim-Trace','true')
                                    .set('Content-Type', 'application/json')
                                     .set('Ocp-Apim-Subscription-Key','b56c9022589e4c7abe979c0dc08929c9'),
};
