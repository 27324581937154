import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-company-main-address-change-modal',
  templateUrl: './company-main-address-change-modal.component.html',
  styleUrls: ['./company-main-address-change-modal.component.css']
})
export class CompanyMainAddressChangeModalComponent implements OnInit {

  @ViewChild('modal') modal: ModalDirective;
  @Input('addressList') addressList;
  @Input('addressTypeList') addressTypes;
  @Input('mainAddressTypeId') mainAddressTypeId;
  @Output('getAddressChangeValues') sendAddressChangeSelection = new EventEmitter();
  showMainAddressChangeWarning: boolean = true;
  existingLocationList: any[];
  selectedLocationOption = 0;
  selectedCompanyLocation: any;
  selectedAddressType: any;
  activeSection = 0;
  modalTitle = "Warning! Main Address Exists";
  companyNameOfNewMainLocation: string;
  companyNameOfExistingMainLocation: string;
  constructor() { }

  ngOnInit(): void {

  }

  show(existingLocationList, companyNameOfNewMainLocation, companyNameOfExistingMainLocation) {
    this.existingLocationList = existingLocationList;
    this.companyNameOfNewMainLocation = companyNameOfNewMainLocation;
    this.companyNameOfExistingMainLocation = companyNameOfExistingMainLocation;
    if (companyNameOfNewMainLocation) {
      this.activeSection = 0;
    }
    else {
      this.activeSection = 5;
    }
    this.setModalTitle();
    this.modal.show();
  }

  setModalTitle() {
    switch (this.activeSection) {
      case 0:
        this.modalTitle = AppConsts.MainAddressExists;
        break;
      case 1:
        this.modalTitle = AppConsts.SelectAddressType;
        break;
      case 2:
        this.modalTitle = AppConsts.SelectParentEntity;
        break;
      case 5:
        this.modalTitle = AppConsts.MainAddressChange;
        break;
      case 6:
        this.modalTitle = AppConsts.SelectAddressType;
        break;
      case 7:
        this.modalTitle = AppConsts.SelectMainAddress;
        break;
      default:
        break;
    }
  }

  close() {
    this.selectedAddressType = null;
    this.selectedCompanyLocation = null;
    this.modal.hide();
  }

  saveAddressDetails() {

    this.sendAddressChangeSelection.emit({ selectedAddressType: this.selectedAddressType, selectedParentCompany: this.selectedCompanyLocation });
    this.activeSection = 0;
    this.modalTitle = AppConsts.MainAddressExists;
  }

  proceedToChangeMainAddress() {
    this.activeSection = this.activeSection == 0 ? 1 : this.activeSection == 1 ? 2 : this.activeSection == 5 ? 6 : 7;
    this.setModalTitle();
  }
}
