import { Injectable } from '@angular/core';
import { Region, ReorderRegion } from '@app/shared/models/config/region.model';
import { Observable } from 'rxjs';
import { HttpBaseService } from '../httpbase.service';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(private httpBaseService: HttpBaseService) { }
  public getRegions(userId): Observable<any> {
    const apiUrl = 'Region/GetRegions/' + userId
    return this.httpBaseService.get(apiUrl);
  }
  createRegion(region: Region): Observable<any> {
    return this.httpBaseService.post('Region/CreateRegion', region);
  }
  updateRegion(region: Region): Observable<any> {
    return this.httpBaseService.post('Region/UpdateRegion', region);
  }
  deleteRegion(region: Region): Observable<any> {
    return this.httpBaseService.post('Region/DeleteRegion', region);
  }
  reorderRegion(region: ReorderRegion): Observable<any> {
    return this.httpBaseService.post('Region/ReorderRegions', region);
  }
}
