<div class="audit-grid-container">
    <dx-data-grid class="dx-grid-max-height" [dataSource]="cparsList" [remoteOperations]="true" [rowAlternationEnabled]="true"
        [showColumnLines]="true" [columnHidingEnabled]="false" [showRowLines]="true" [showBorders]="true"
        [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)">
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-search-panel [visible]="true" [width]="100+'%'" [(text)]="searchString"></dxo-search-panel>
        <dxo-state-storing [enabled]="true" type="localStorage" storageKey="auditListStorageName">
        </dxo-state-storing>
        <div *dxTemplate="let cell of 'viewIcon'">
            <a (click)="viewAuditDetails(cell.data)" class="a-link" target="_blank">{{ cell.text }}</a>
        </div>
        <dxi-column caption="CPAR Id" dataField="id" [width]="120" alignment="center"
            cellTemplate="viewIcon"></dxi-column>
        <dxi-column caption="CPAR#" dataField="cparId" [width]="120" alignment="center"
            cellTemplate="viewIcon"></dxi-column>
        <dxi-column caption="Locations" dataField="locations" alignment="left"></dxi-column>
        <dxi-column caption="Description#" dataField="descriptionOfIssue" alignment="center"></dxi-column>
        <dxi-column caption="Status" dataField="cparStatusName" [width]="200" alignment="left"></dxi-column>

        <div *dxTemplate="let data of 'pick-audit-location'">
            <label>Locations</label>
            <ng-select [selectOnTab]="true" [items]="locationList" bindLabel="locationName"
                bindValue="companyLocationId" [ngModel]="companyLocationId" (clear)="clearLocationSelection();"
                (ngModelChange)="onAuditLocationChange($event)" [searchable]="false" [clearable]="true">
            </ng-select>
        </div>
        <dxo-paging [pageSize]="200"></dxo-paging>
        <dxo-pager [showInfo]="true">
        </dxo-pager>
    </dx-data-grid>
</div>
