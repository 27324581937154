import { Injectable } from '@angular/core';
import { HttpBaseService } from '@app/shared/services/httpbase.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService {

  constructor(private httpBaseService: HttpBaseService) { }

  public getAuditChecklistByAuditId(id, userId): Observable<any> {
    const apiUrl = 'AuditChecklist/GetAuditChecklistByAuditId/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public getAuditChecklistById(id, userId): Observable<any> {
    const apiUrl = 'AuditChecklist/GetAuditChecklistById/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
  
}
