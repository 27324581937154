import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-future-revenue',
  templateUrl: './widget-future-revenue.component.html',
  styleUrls: ['./widget-future-revenue.component.css']
})
export class WidgetFutureRevenueComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
