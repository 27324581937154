<div class="ncr-grid-container">
    <dx-data-grid class="dx-grid-max-height" [dataSource]="NCRList" [rowAlternationEnabled]="false"
        [showColumnLines]="false" [columnHidingEnabled]="true" [showRowLines]="true" [showBorders]="true"
        [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)" [remoteOperations]="true">

        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-search-panel [visible]="true" [width]="100+'%'" [text]="searchString"></dxo-search-panel>
        <dxi-column caption="" [width]="40" cellTemplate="viewIcon">
        </dxi-column>
        <div *dxTemplate="let cell of 'viewIcon'">
            <div style="text-align: center; width: 100%;" (click)="viewNCR(cell.data.id)">
                <i class="fa fa-search" style="color: var(--primary-color);" title="View"></i>
            </div>
        </div>
        <dxi-column caption="NCR Id" dataField="id" dataType="string" [width]="140" cellTemplate="viewCellTemplate">
        </dxi-column>
        <div *dxTemplate="let cell of 'viewCellTemplate'">
            <a (click)="viewNCR(cell.data.id)" class="a-link" target="_blank">{{ cell.text }}</a>
        </div>
        <dxi-column caption="NCR Number" dataField="ncrNumber" [width]="140" dataType="string"></dxi-column>
        <dxi-column caption="NCR Type" dataField="ncrType" dataType="string" [width]="140"></dxi-column>
        <dxi-column caption="Standard" dataField="standard" dataType="string" [width]="350"></dxi-column>
        <dxi-column caption="Customer#" dataField="companyId" dataType="string" [width]="140">
        </dxi-column>
        <dxi-column caption="Customer" dataField="companyName" dataType="string" [width]="330"></dxi-column>
        <dxi-column caption="Location" dataField="locationName" dataType="string" [width]="200">
        </dxi-column>
        <dxi-column caption="Address" dataField="locationAddress" dataType="string">
        </dxi-column>
        <dxo-paging [pageSize]="200">
        </dxo-paging>
        <dxo-pager [showInfo]="true">
        </dxo-pager>
    </dx-data-grid>
</div>
