import { Injectable } from '@angular/core';
import { RegistrationStatus, ReorderRegistrationStatus } from '@app/shared/models/config/registration-status.model';
import { Observable } from 'rxjs';
import { HttpBaseService } from '../httpbase.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationStatusService {

  constructor(private httpBaseService: HttpBaseService) { }

  public getRegistrationStatuses(userId): Observable<any> {
    const apiUrl = 'RegistrationStatus/GetRegistrationStatuses/'+ userId
    return this.httpBaseService.get(apiUrl);
  }
  
  createRegistrationStatus(registrationStatus: RegistrationStatus): Observable<any> {
    return this.httpBaseService.post('RegistrationStatus/CreateRegistrationStatus', registrationStatus);
  }
  updateRegistrationStatus(registrationStatus: RegistrationStatus): Observable<any> {
    return this.httpBaseService.post('RegistrationStatus/UpdateRegistrationStatus', registrationStatus);
  }
  deleteRegistrationStatus(registrationStatus: RegistrationStatus): Observable<any> {
    return this.httpBaseService.post('RegistrationStatus/DeleteRegistrationStatus', registrationStatus);
  }
  reorderRegistrationStatus(registrationStatus: ReorderRegistrationStatus): Observable<any> {
    return this.httpBaseService.post('RegistrationStatus/ReorderRegistrationStatuses', registrationStatus);
  }
}
