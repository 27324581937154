import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  clientData = null;
  clientDataBS: BehaviorSubject<any>;

  constructor() {
    this.clientDataBS = new BehaviorSubject(this.clientData);
  }

  next(clientData) {
    this.clientData = clientData;
    this.clientDataBS.next(this.clientData);
  }
}
