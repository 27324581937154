import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-application-quoted',
  templateUrl: './widget-application-quoted.component.html',
  styleUrls: ['./widget-application-quoted.component.css']
})
export class WidgetApplicationQuotedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
