import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Injector } from '@angular/core';
import { CompanyService } from '@app/company/shared/services/company.service';
import { SearchCompanyDto } from '@app/shared/models/company/search-company-dto';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AutoComplete } from 'primeng/autocomplete';


@Component({
    selector: 'app-company-typeahead-dropdown',
    templateUrl: './company-typeahead-dropdown.component.html',
    styleUrls: ['./company-typeahead-dropdown.component.css']
})
export class CompanyTypeaheadDropdownComponent extends AppComponentBase implements OnInit {

    @Input('readOnly') readOnly: boolean;
    companyTypeaheadSuggestions = [];
    filterCompany = '';
    filterCompanyId: number = 0;
    @Input('selectedItem') filterText;
    @Input('companyTypeToBeReturned') companyTypeToBeReturned;
    @Input('showClientIcon') showClientIcon = false;
    @Output('getSelectedItem') sendSelectedItem = new EventEmitter();
    @Output('onDataClear') onDataClear = new EventEmitter();
    @ViewChild('autocomplete') autocomplete: AutoComplete;
    constructor(private injector: Injector, private companyService: CompanyService) {
        super(injector);
    }

    ngOnInit(): void {
    }

    onSearchItemSelect(item) {
        this.filterCompany = item["name"];
        this.filterCompanyId = item["id"];
        this.sendSelectedItem.emit(item);
    }

    onChangeSearch(item) {
        this.filterCompany = item["query"];
        this.getCompanies();
        // this.companyTypeaheadSuggestions = this.filterByValue(this.companies, item.query);
        // if (this.filterCompany != '') {
        //     this.sendSelectedItem.emit(null);
        // }
    }

    clearControl(autocomplete: any) {
        this.filterText = '';
        this.filterCompany = '';
        this.filterCompanyId = 0;
        autocomplete.value = '';
        // autocomplete._suggestions = this.companies;
        // autocomplete.handleDropdownClick();
        this.onDataClear.emit();

    }

    getCompanies() {
        var companySearchRequest = new SearchCompanyDto();
        companySearchRequest.searchString = this.filterCompany;
        companySearchRequest.companyTypeToBeReturned = this.companyTypeToBeReturned;
        companySearchRequest.userId = this.appSession.user.id;
        if (this.filterCompany) {
            this.companyService.getCompanyBasedOnSearchString(companySearchRequest).subscribe(
                (data: any) => {
                    this.companyTypeaheadSuggestions = [];
                    this.companyTypeaheadSuggestions = data.companyBasedOnSearchString ? data.companyBasedOnSearchString : [];
                }, (error: any) => {
                    this.companyTypeaheadSuggestions = [];
                    abp.log.error(error);
                    abp.notify.error("", "Failed to load companies", { showCloseButton: true, timer: 10000 });
                }
            )
        }
    }

    // filterByValue(searchArray, searchKey) {
    //     return searchArray.filter(o =>
    //         Object.keys(o).some(k => o[k] != null && o[k].toString().toLowerCase().includes(searchKey.toString().toLowerCase())));
    // }



}
