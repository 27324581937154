import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-approved-quotes',
  templateUrl: './widget-approved-quotes.component.html',
  styleUrls: ['./widget-approved-quotes.component.css']
})
export class WidgetApprovedQuotesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
