<div dropdown class="topbar-item" data-toggle="dropdown" aria-expanded="true"
    [dropup]="isDropup" container="{{isDropup ? null: 'body'}}" >
    <div dropdownToggle [class]="customStyle" id="notificationIcon">
        <!-- <i class="flaticon-alert-2 unread-notification" *ngIf="unreadNotificationCount"></i> -->
        <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="unreadNotificationCount">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8646 1.28952C15.0203 1.97328 17.4155 4.98758 17.4155 8.57464C17.4155 12.8037 17.042 15.984 21 18.8162C13.989 18.8162 7.01068 18.8162 0 18.8162C3.9688 15.9762 3.59403 12.7348 3.59403 8.57464C3.59403 4.9683 6.01534 1.94127 9.19595 1.27866C9.37942 -0.432135 11.693 -0.423899 11.8646 1.28952ZM13.3531 20.4379C13.1097 21.8951 11.9249 23 10.4997 23C9.07463 23 7.89008 21.8953 7.64621 20.4379H13.3531Z" fill="#086CCC"/>
        </svg>
        <span class="label label-warning unread-notification-count" *ngIf="unreadNotificationCount">
            {{unreadNotificationCount}}
        </span>
        <i class="flaticon-alarm" *ngIf="!unreadNotificationCount"></i>
    </div>
    <div *dropdownMenu
        class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-lg">
        <perfect-scrollbar>
            <div class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                [ngStyle]="{ 'background': 'url(' + appRootUrl() + 'assets/metronic/themes/default/images/bg/bg-1.jpg)', 'background-size': 'cover'}">
                <h4 class="d-flex flex-center rounded-top">
                    <span class="text-white">{{"Notifications" | localize}}</span>
                    <span
                        class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">{{unreadNotificationCount}}
                        {{"New" | localize}}</span>
                </h4>
            </div>
            <div>
                <div class="row m-4">
                    <div class="text-left col-md-8">
                        <a href="javascript:;"
                            (click)="setAllNotificationsAsRead(); $event.preventDefault(); $event.stopPropagation();"
                            id="setAllNotificationsAsReadLink"
                            *ngIf="unreadNotificationCount">{{"SetAllAsRead" | localize}}</a>
                    </div>
                    <div class="text-right col-md-4">
                        <a id="openNotificationSettingsModalLink" class="text-right" href="javascript:;"
                            (click)="openNotificationSettingsModal()">{{"Settings" | localize}}</a>
                    </div>
                </div>
                <hr />

                <div class="navi navi-hover my-4 ps" data-scroll="true" data-height="300" data-mobile-height="200"
                    style="height: 300px; overflow: hidden;">

                    <a *ngFor="let notification of notifications" (click)="gotoUrl(notification.url)"
                        [ngClass]="{'user-notification-item-clickable': notification.url,'user-notification-item-unread': notification.isUnread}"
                        class="navi-item">
                        <div class="navi-link">
                            <div class="navi-icon mr-2">
                                <i class="{{notification.icon}} {{notification.iconFontClass}}"></i>
                            </div>
                            <div class="navi-text">
                                <div class="font-weight-bold">
                                    {{notification.text}}
                                </div>
                                <div class="text-muted">
                                    {{notification.time | luxonFromNow}}
                                </div>
                                <span *ngIf="notification.isUnread"
                                    class="btn btn-link-success set-notification-as-read"
                                    (click)="setNotificationAsRead(notification); $event.preventDefault(); $event.stopPropagation();">
                                    {{"SetAsRead" | localize}}
                                </span>
                            </div>
                        </div>
                    </a>
                    <span *ngIf="!notifications.length" class="notification-empty-text p-3">
                        {{"ThereAreNoNotifications" | localize}}
                    </span>
                </div>
                <hr *ngIf="notifications.length" />
                <div class="m-4" *ngIf="notifications.length">
                    <div class="text-center"><a routerLink="notifications">{{"SeeAllNotifications" | localize}}</a>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</div>
