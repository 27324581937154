import { Component, Injector, OnInit, Input } from '@angular/core';
import { ThemesLayoutBaseComponent } from '../themes/themes-layout-base.component';
import { ProfileServiceProxy } from '@shared/service-proxies/service-proxies';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';

@Component({
    selector: 'system-select',
    templateUrl: './system-select.component.html'
})
export class SystemSelectComponent extends ThemesLayoutBaseComponent implements OnInit {

    @Input() isDropup = false;
    @Input() customStyle = 'btn btn-icon btn-dropdown btn-clean btn-lg mr-1';

    systems = [
                {"id": 5201, "Name": "Core"},
                {"id": 5202, "Name": "NQA"},
                {"id": 5203, "Name": "Customer"},
                {"id": 5204, "Name": "Auditor"}
              ]

    public constructor(
        injector: Injector,
        _dateTimeService: DateTimeService,
        private _profileServiceProxy: ProfileServiceProxy
    ) {
        super(injector, _dateTimeService);
    }

    ngOnInit(): void {
    }

    switchSystem(sys) {
      location.href='http://app.nts-blueocean.com:' + sys + '/';
    }
}
