/**
 * @description : This base service provides angular get and post functionality to other pages
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';


@Injectable()
export class HttpBaseService {
  constructor(public http: HttpClient) {
  }

  /**
   * Makes the http get request call using the http client
   * @params {string} url
   * @params {HttpParams} urlParams
   */
  get(url: string, urlParams?: HttpParams): Observable<any> {
    return this.http.get(environment.apiBaseUrl + url, { params: urlParams, headers: environment.dataApiHeaders });
  }

  public post(url: string,body: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + url, body, { headers: environment.dataApiHeaders });
  }

    public postServiceBus(url: string,body: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + url, body, { headers: environment.dataApiHeaders, responseType: 'blob'});
  }












}
