import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBaseService } from '../httpbase.service';
import { Country } from '@app/shared/models/country';
import { COUNTRIES } from '@app/shared/data/country-data';

@Injectable({
    providedIn: 'root'
})
export class CountryService {

    constructor(private httpBaseService: HttpBaseService) { }

    public getCountries(userId): Observable<any> {
        const apiUrl = 'Country/GetCountries/' + userId;
        return this.httpBaseService.get(apiUrl);
    }

    public getCountryList():Country[]{
        return COUNTRIES;
    }
}

