<dx-data-grid [dataSource]="quoteList" [rowAlternationEnabled]="false" [showColumnLines]="false"
            [columnHidingEnabled]="false" [showRowLines]="true" [showBorders]="true" [remoteOperations]="true"
            (onToolbarPreparing)="onToolbarPreparing($event)" [wordWrapEnabled]="true" [width]="100+'%'">
            <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
            <dxo-search-panel [visible]="true" [text]="searchString" [width]="100+'%'"></dxo-search-panel>
            <dxi-column caption="" [width]="40" cellTemplate="viewIcon">
            </dxi-column>
            <div *dxTemplate="let cell of 'viewIcon'">
                <div style="text-align: center; width: 100%;" (click)="cloneQuote(cell.data)">
                    <i class="fa fa-copy" style="color: var(--primary-color); cursor: pointer;" title="Clone"></i>
                </div>
            </div>
            <dxi-column caption="Quote ID" dataField="id" [width]="120" alignment="left" cellTemplate="viewQuote"></dxi-column>
            <div *dxTemplate="let cell of 'viewQuote'">
                <a (click)="viewQuote(cell.data.id)" class="a-link" target="_blank">{{ cell.text }}</a>
            </div>
            <dxi-column caption="Company Name" [minWidth]="280" dataField="clientName" alignment="left" cellTemplate="openClientPopUp">
                  
            <div *dxTemplate="let cell of 'openClientPopUp'">
                <div *ngIf="cell.data.companyId > 0">
                    <a (click)="openClientPopUp(cell.data.companyId)">{{cell.text}}</a>
                </div>
                <div *ngIf="cell.data.companyId == 0">
                     {{cell.text}} 
                </div>
            </div>
            </dxi-column>
            <dxi-column caption="Standard Group(s)" [minWidth]="250" dataField="standardGroups" alignment="left"></dxi-column>
            <dxi-column caption="Status" [width]="180" dataField="statusName" alignment="left"></dxi-column>
            <dxi-column caption="Created" [width]="100" dataField="creationTime" cellTemplate="dateCell" alignment="left">
            </dxi-column>
            <div *dxTemplate="let cellData of 'dateCell'">
                {{cellData.value | date: 'MM/dd/yyyy'}}
            </div>
        </dx-data-grid>
