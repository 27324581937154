import { Pipe, PipeTransform } from "@angular/core";
import { orderBy } from "lodash-es";

@Pipe({
    name: "orderBy"
})
export class OrderByPipe implements PipeTransform {
    transform(array: any, sortBy: any, order?: string): any[] {
        if (order == "asc") {
            return orderBy(array, sortBy, ["asc"]);
        } else if (order == "desc") {
            return orderBy(array, sortBy, ["desc"]);
        } else {
            return orderBy(array, sortBy, ["asc"]);
        }
    }
}
