<dx-data-grid [dataSource]="companyCertificateIssuanceList" [rowAlternationEnabled]="false" [showColumnLines]="false"
    [columnHidingEnabled]="false" [showRowLines]="true" [showBorders]="true" [remoteOperations]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)" [wordWrapEnabled]="true" [width]="100+'%'">
    <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
    <dxo-search-panel [visible]="true" [text]="searchString" [width]="100+'%'"></dxo-search-panel>
    <!-- <dxi-column type="buttons" caption="" [width]="50">
                <dxi-button text="Edit" icon="edit" [onClick]="editCompanyCertificateIssuance">
                </dxi-button>
            </dxi-column> -->
    <!-- <dxi-column type="buttons" caption="" [width]="50">
                <dxi-button text="Delete" icon="trash" [onClick]="deleteCompanyCertificateIssuance">
                </dxi-button>
            </dxi-column> -->
    <dxi-column caption="Certificate#" dataField="certNo" [width]="300" alignment="left"
        cellTemplate="viewCompanyCertificateIssuance"></dxi-column>
    <dxi-column caption="Certificate Id" dataField="id" dataType="string" [width]="120" alignment="left"
        cellTemplate="viewCompanyCertificateIssuance"></dxi-column>
    <div *dxTemplate="let cell of 'viewCompanyCertificateIssuance'">
        <a (click)="viewCompanyCertificateIssuance(cell.data.id)" class="a-link" target="_blank">{{ cell.text }}</a>
    </div>
    <dxi-column caption="Company Name" [width]="220" dataField="companyName" alignment="left"
        cellTemplate="openClientPopUp">

        <div *dxTemplate="let cell of 'openClientPopUp'">
            <div *ngIf="cell.data.companyId > 0">
                <a (click)="openClientPopUp(cell.data.companyId)">{{cell.text}}</a>
            </div>
            <div *ngIf="cell.data.companyId == 0">
                {{cell.text}}
            </div>
        </div>
    </dxi-column>
    <dxi-column caption="Standard" [width]="220" dataField="standardName" alignment="left"
        cellTemplate="viewCompanyCertificateIssuance"></dxi-column>
    <dxi-column caption="Status" dataField="statusName" alignment="left"></dxi-column>
    <dxi-column caption="Scope" dataField="scope" alignment="left"></dxi-column>
    <dxi-column caption="Facility Scope" dataField="facilityScope" alignment="left"></dxi-column>
    <dxi-column caption="Issued Date" dataField="origCertDate" alignment="left"></dxi-column>
    <dxi-column caption="Region" dataField="regionName" alignment="left"></dxi-column>
    <!-- <dxi-column caption="Created" [width]="120" dataField="creationTime" cellTemplate="dateCell" alignment="left">
    </dxi-column>
    <div *dxTemplate="let cellData of 'dateCell'">
        {{cellData.value | date: 'MM/dd/yyyy'}}
    </div> -->
</dx-data-grid>
