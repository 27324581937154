import { Injectable } from '@angular/core';
import { HttpBaseService } from '../httpbase.service';
import { Observable } from 'rxjs';
import { Classification, ReorderClassification } from '@app/shared/models/config/classification.model';

@Injectable({
  providedIn: 'root'
})
export class ClassificationService {

  constructor(private httpBaseService: HttpBaseService) { }

  public getClassifications(userId): Observable<any> {
    const apiUrl = 'Classification/GetClassifications/'+ userId
    return this.httpBaseService.get(apiUrl);
  }
  
  createClassification(classification: Classification): Observable<any> {
    return this.httpBaseService.post('Classification/CreateClassification', classification);
  }
  updateClassification(classification: Classification): Observable<any> {
    return this.httpBaseService.post('Classification/UpdateClassification', classification);
  }
  deleteClassification(classification: Classification): Observable<any> {
    return this.httpBaseService.post('Classification/DeleteClassification', classification);
  }
  reorderClassification(classification: ReorderClassification): Observable<any> {
    return this.httpBaseService.post('Classification/ReorderClassifications', classification);
  }

}
