        <div class="card card-custom card-border">
            <div class="card-header">
                <div class="card-title">
                    <span class="card-icon">
                        <i class="flaticon2-pin text-primary"></i>
                    </span>
                    <h3 class="card-label">Revenue Summary
                    <small></small></h3>
                </div>
            </div>
            <div class="card-body pt-2">
                <div class="row">
                    <div class="col" style="max-height: 300px" [perfectScrollbar]>
                        <table style="width:100%">
                            <tr>
                                <td><b>Standard Name</b></td>
                                <td><b>Projected Cost</b></td>
                                <td><b>Actual Cost</b></td>
                            </tr>
                            <tr *ngFor="let item of revenueSummary">
                                <td>{{item.StandardName}}</td>
                                <td>{{item.ProjectedCost}}</td>
                                <td>{{item.ActualCost}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
