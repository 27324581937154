import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import CustomStore from 'devextreme/data/custom_store';
import { ChecklistService } from '../shared/services/checklist/checklist.service';

@Component({
  selector: 'app-checklist-grid',
  templateUrl: './checklist-grid.component.html',
  styleUrls: ['./checklist-grid.component.css']
})
export class ChecklistGridComponent extends AppComponentBase implements OnInit {

  @Input('auditId') auditId;
  searchString = '';
  companyLocationId;
  isView: boolean = window.location.pathname.indexOf('/view') === -1 ? false : true;
  checklists: any = [];

  constructor(private router: Router,
    private injector: Injector,
    private checklistService: ChecklistService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getChecklists();
  }

  editChecklist = (e) => {
    this.router.navigate([`app/checklist/${e.row.data.id}/edit`])
  }

  viewChecklist(data: any) {
    if (this.isView) {
      this.router.navigate([`app/checklist/view/${data}`]);
    }
    else {
      this.router.navigate([`app/checklist/edit/${data}`]);
    }
  }

  getChecklists() {
    this.spinnerService.show();
    this.checklistService.getAuditChecklistByAuditId(this.auditId, this.appSession.user.id).subscribe(
      (data: any) => {
        this.checklists = data;
        this.spinnerService.hide();
      }, (error: any) => {
        this.spinnerService.hide();
        abp.log.error(error);
        abp.notify.error("", "Failed to retrieve checklists", { showCloseButton: true, timer: 10000 });
      }
    )
    // var self = this;
    // this.checklists = new CustomStore({
    //   key: "id",
    //   load: function (loadOptions: any) {
    //     const searchString = loadOptions.filter ? (loadOptions.filter[0].filterValue + '').trim() : ""
    //     let dto = {
    //       searchString: searchString,
    //       pageSize: loadOptions.take,
    //       pageNumber: (self.searchString != searchString) ? 1 : (loadOptions.skip / loadOptions.take) + 1,
    //       userId: self.appSession.user.id
    //     }
    //     let searchDto;

    //     searchDto = dto;

    //     if (self.auditId) {
    //       searchDto = {
    //         ...dto,
    //         auditId: self.auditId
    //       }
    //     }
    //     self.searchString = searchDto.searchString;
    //     self.spinnerService.show();
    //     return self.checklistService.getAuditChecklistByAuditId(searchDto)
    //       .toPromise()
    //       .then((data: any) => {
    //         self.spinnerService.hide();
    //         return {
    //           data: data.ncrList ? data.ncrList : [],
    //           totalCount: data.totalItemsCount
    //         };
    //       })
    //       .catch(error => {
    //         self.spinnerService.hide();
    //         abp.log.error(error);
    //         abp.notify.error("", "Failed to retrieve Checklists", { showCloseButton: true, timer: 10000 });
    //       });
    //   }
    // });
  }
}
