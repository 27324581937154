import { Injectable } from '@angular/core';
import { BusinessType, ReorderBusinessType } from '@app/shared/models/config/business-type.model';
import { Observable } from 'rxjs';
import { HttpBaseService } from '../httpbase.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessTypeService {

  constructor(private httpBaseService: HttpBaseService) { }

  public getBusinessTypes(userId): Observable<any> {
    const apiUrl = 'BusinessType/GetBusinessTypes/' + userId
    return this.httpBaseService.get(apiUrl);
  }

  createBusinessType(businessType: BusinessType): Observable<any> {
    return this.httpBaseService.post('BusinessType/CreateBusinessType', businessType);
  }

  updateBusinessType(businessType: BusinessType): Observable<any> {
    return this.httpBaseService.post('BusinessType/UpdateBusinessType', businessType);
  }
  
  deleteBusinessType(businessType: BusinessType): Observable<any> {
    return this.httpBaseService.post('BusinessType/DeleteBusinessType', businessType);
  }

  reorderBusinessTypes(businessType: ReorderBusinessType): Observable<any> {
    return this.httpBaseService.post('BusinessType/ReorderBusinessTypes', businessType);
  }

}
