import { Injectable } from '@angular/core';
import { HttpBaseService } from '../httpbase.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AddressService {

    constructor(private httpBaseService: HttpBaseService) { }

    public GetAddressesByCompanyId(companyId, userId): Observable<any> {
        const apiUrl = 'Address/GetAddressesByCompanyId/' + companyId + '/'+ userId
        return this.httpBaseService.get(apiUrl);
    }

    public deleteAddress(deleteAddressRequest): Observable<any> {
        const apiUrl = 'Address/DeleteAddress';
        return this.httpBaseService.post(apiUrl, deleteAddressRequest);
    }

    public CreateAddress(createAddressRequest): Observable<any> {
        const apiUrl = 'Address/CreateAddress';
        return this.httpBaseService.post(apiUrl, createAddressRequest);
    }

     public UpdateAddress(addressRequest): Observable<any> {
        const apiUrl = 'Address/UpdateAddress';
        return this.httpBaseService.post(apiUrl, addressRequest);
    }
}
