import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBaseService } from '../httpbase.service';

@Injectable({
  providedIn: 'root'
})
export class JustificationService {

  constructor(private httpBaseService: HttpBaseService) { }

  public getJustifications(userId): Observable<any> {
    const apiUrl = 'Justification/GetJustificationsByOwnerId/'+ userId
    return this.httpBaseService.get(apiUrl);
  }
  getJustificationsByOwnerId(id, userId): Observable<any> {
    const apiUrl = 'Justification/GetJustificationsByOwnerId/'+ id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
  getJustificationsByClientId(id, userId): Observable<any> {
    const apiUrl = 'Justification/GetJustificationsByClientId/'+ id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
  getJustificationById(id, userId): Observable<any> {
    const apiUrl = 'Justification/GetJustificationById/'+ id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
  createJustificationConsolidated(justification: any): Observable<any> {
    return this.httpBaseService.post('Justification/CreateJustificationConsolidated', justification);
  }
  updateJustification(justification: any): Observable<any> {
    return this.httpBaseService.post('Justification/UpdateJustification', justification);
  }
  deleteJustification(justification: any): Observable<any> {
    return this.httpBaseService.post('Justification/DeleteJustification', justification);
  }
}
