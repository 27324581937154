import { Country } from "../models/country";

export const COUNTRIES: Country[] = [
    { Id: 4, Name: "Afghanistan" },
    { Id: 8, Name: "Albania" },
    { Id: 10, Name: "Antarctica" },
    { Id: 12, Name: "Algeria" },
    { Id: 16, Name: "American Samoa" },
    { Id: 20, Name: "Andorra" },
    { Id: 24, Name: "Angola" },
    { Id: 28, Name: "Antigua and Barbuda" },
    { Id: 31, Name: "Azerbaijan" },
    { Id: 32, Name: "Argentina" },
    { Id: 36, Name: "Australia" },
    { Id: 40, Name: "Austria" },
    { Id: 44, Name: "Bahamas" },
    { Id: 48, Name: "Bahrain" },
    { Id: 50, Name: "Bangladesh" },
    { Id: 51, Name: "Armenia" },
    { Id: 52, Name: "Barbados" },
    { Id: 56, Name: "Belgium" },
    { Id: 60, Name: "Bermuda" },
    { Id: 64, Name: "Bhutan" },
    { Id: 68, Name: "Bolivia" },
    { Id: 70, Name: "Bosnia and Herzegovina" },
    { Id: 72, Name: "Botswana" },
    { Id: 74, Name: "Bouvet Island" },
    { Id: 76, Name: "Brazil" },
    { Id: 84, Name: "Belize" },
    { Id: 86, Name: "British Indian Ocean Territory" },
    { Id: 90, Name: "Solomon Islands" },
    { Id: 92, Name: "British Virgin Islands" },
    { Id: 96, Name: "Brunei Darussalam" },
    { Id: 100, Name: "Bulgaria" },
    { Id: 104, Name: "Myanmar" },
    { Id: 108, Name: "Burundi" },
    { Id: 112, Name: "Belarus" },
    { Id: 116, Name: "Cambodia" },
    { Id: 120, Name: "Cameroon" },
    { Id: 124, Name: "Canada" },
    { Id: 132, Name: "Cape Verde" },
    { Id: 136, Name: "Cayman Islands" },
    { Id: 140, Name: "Central African Republic" },
    { Id: 144, Name: "Sri Lanka" },
    { Id: 148, Name: "Chad" },
    { Id: 152, Name: "Chile" },
    { Id: 156, Name: "China" },
    { Id: 158, Name: "Taiwan" },
    { Id: 162, Name: "Colombia" },
    { Id: 166, Name: "Comoros" },
    { Id: 170, Name: "Congo (Brazzaville)" },
    { Id: 174, Name: "Congo, (Kinshasa)" },
    { Id: 175, Name: "Mayotte" },
    { Id: 178, Name: "Cook Islands" },
    { Id: 180, Name: "Costa Rica" },
    { Id: 184, Name: "Côte d'Ivoire" },
    { Id: 188, Name: "Croatia" },
    { Id: 191, Name: "Cyprus" },
    { Id: 192, Name: "Czech Republic" },
    { Id: 196, Name: "Denmark" },
    { Id: 203, Name: "Djibouti" },
    { Id: 204, Name: "Benin" },
    { Id: 208, Name: "Dominica" },
    { Id: 212, Name: "Ecuador" },
    { Id: 214, Name: "Egypt" },
    { Id: 218, Name: "El Salvador" },
    { Id: 222, Name: "Eritrea" },
    { Id: 226, Name: "Estonia" },
    { Id: 231, Name: "Faroe Islands" },
    { Id: 232, Name: "Ethiopia" },
    { Id: 233, Name: "Falkland Islands (Malvinas)" },
    { Id: 234, Name: "Finland" },
    { Id: 238, Name: "Fiji" },
    { Id: 239, Name: "South Georgia and the South Sandwich Islands" },
    { Id: 242, Name: "France" },
    { Id: 246, Name: "French Guiana" },
    { Id: 248, Name: "Åland Islands" },
    { Id: 250, Name: "French Polynesia" },
    { Id: 254, Name: "French Southern Territories" },
    { Id: 258, Name: "Gabon" },
    { Id: 260, Name: "Gambia" },
    { Id: 262, Name: "Dominican Republic" },
    { Id: 266, Name: "Georgia" },
    { Id: 268, Name: "Ghana" },
    { Id: 270, Name: "Germany" },
    { Id: 275, Name: "Palestinian Territory" },
    { Id: 276, Name: "Gibraltar" },
    { Id: 288, Name: "Greece" },
    { Id: 292, Name: "Greenland" },
    { Id: 296, Name: "Macao, SAR China" },
    { Id: 300, Name: "Grenada" },
    { Id: 304, Name: "Iceland" },
    { Id: 308, Name: "Guadeloupe" },
    { Id: 312, Name: "Guam" },
    { Id: 316, Name: "Guatemala" },
    { Id: 320, Name: "Guernsey" },
    { Id: 324, Name: "Guinea-Bissau" },
    { Id: 328, Name: "Haiti" },
    { Id: 332, Name: "Heard and Mcdonald Islands" },
    { Id: 334, Name: "Holy See (Vatican City State)" },
    { Id: 336, Name: "Honduras" },
    { Id: 340, Name: "Hungary" },
    { Id: 344, Name: "Christmas Island" },
    { Id: 348, Name: "Hong Kong, SAR China" },
    { Id: 352, Name: "India" },
    { Id: 356, Name: "Indonesia" },
    { Id: 360, Name: "Iran, Islamic Republic of" },
    { Id: 364, Name: "Iraq" },
    { Id: 368, Name: "Ireland" },
    { Id: 372, Name: "Isle of Man" },
    { Id: 376, Name: "Italy" },
    { Id: 380, Name: "Jamaica" },
    { Id: 384, Name: "Cuba" },
    { Id: 388, Name: "Japan" },
    { Id: 392, Name: "Jersey" },
    { Id: 398, Name: "Kenya" },
    { Id: 400, Name: "Kazakhstan" },
    { Id: 404, Name: "Kiribati" },
    { Id: 408, Name: "Korea (North)" },
    { Id: 410, Name: "Korea (South)" },
    { Id: 414, Name: "Kuwait" },
    { Id: 417, Name: "Kyrgyzstan" },
    { Id: 418, Name: "Lao PDR" },
    { Id: 422, Name: "Lebanon" },
    { Id: 426, Name: "Lesotho" },
    { Id: 428, Name: "Latvia" },
    { Id: 430, Name: "Liberia" },
    { Id: 434, Name: "Libya" },
    { Id: 438, Name: "Liechtenstein" },
    { Id: 440, Name: "Lithuania" },
    { Id: 442, Name: "Luxembourg" },
    { Id: 446, Name: "Cocos (Keeling) Islands" },
    { Id: 450, Name: "Madagascar" },
    { Id: 454, Name: "Malawi" },
    { Id: 458, Name: "Malaysia" },
    { Id: 462, Name: "Maldives" },
    { Id: 466, Name: "Mali" },
    { Id: 470, Name: "Malta" },
    { Id: 474, Name: "Martinique" },
    { Id: 478, Name: "Mauritania" },
    { Id: 480, Name: "Mauritius" },
    { Id: 484, Name: "Mexico" },
    { Id: 492, Name: "Monaco" },
    { Id: 496, Name: "Mongolia" },
    { Id: 498, Name: "Moldova" },
    { Id: 499, Name: "Montenegro" },
    { Id: 500, Name: "Montserrat" },
    { Id: 504, Name: "Morocco" },
    { Id: 508, Name: "Mozambique" },
    { Id: 512, Name: "Oman" },
    { Id: 516, Name: "Namibia" },
    { Id: 520, Name: "Nauru" },
    { Id: 524, Name: "Nepal" },
    { Id: 528, Name: "Netherlands" },
    { Id: 530, Name: "Netherlands Antilles" },
    { Id: 531, Name: "Curaçao" },
    { Id: 533, Name: "Aruba" },
    { Id: 535, Name: "Bonaire, Sint Eustatius and Saba" },
    { Id: 540, Name: "New Caledonia" },
    { Id: 548, Name: "Vanuatu" },
    { Id: 554, Name: "New Zealand" },
    { Id: 558, Name: "Nicaragua" },
    { Id: 562, Name: "Niger" },
    { Id: 566, Name: "Nigeria" },
    { Id: 570, Name: "Niue" },
    { Id: 574, Name: "Norfolk Island" },
    { Id: 578, Name: "Norway" },
    { Id: 580, Name: "Northern Mariana Islands" },
    { Id: 581, Name: "US Minor Outlying Islands" },
    { Id: 583, Name: "Micronesia, Federated States of" },
    { Id: 584, Name: "Marshall Islands" },
    { Id: 585, Name: "Palau" },
    { Id: 586, Name: "Pakistan" },
    { Id: 591, Name: "Panama" },
    { Id: 598, Name: "Papua New Guinea" },
    { Id: 600, Name: "Paraguay" },
    { Id: 604, Name: "Peru" },
    { Id: 608, Name: "Philippines" },
    { Id: 612, Name: "Pitcairn" },
    { Id: 616, Name: "Poland" },
    { Id: 620, Name: "Portugal" },
    { Id: 624, Name: "Guyana" },
    { Id: 626, Name: "Timor-Leste" },
    { Id: 630, Name: "Puerto Rico" },
    { Id: 634, Name: "Qatar" },
    { Id: 638, Name: "Réunion" },
    { Id: 642, Name: "Romania" },
    { Id: 643, Name: "Russian Federation" },
    { Id: 646, Name: "Rwanda" },
    { Id: 652, Name: "Saint-Barthélemy" },
    { Id: 654, Name: "Saint Helena" },
    { Id: 659, Name: "Saint Kitts and Nevis" },
    { Id: 660, Name: "Anguilla" },
    { Id: 662, Name: "Saint Lucia" },
    { Id: 663, Name: "Saint-Martin (French part)" },
    { Id: 666, Name: "Saint Pierre and Miquelon" },
    { Id: 670, Name: "Saint Vincent and Grenadines" },
    { Id: 674, Name: "San Marino" },
    { Id: 678, Name: "Sao Tome and Principe" },
    { Id: 682, Name: "Saudi Arabia" },
    { Id: 686, Name: "Senegal" },
    { Id: 688, Name: "Serbia" },
    { Id: 690, Name: "Seychelles" },
    { Id: 694, Name: "Sierra Leone" },
    { Id: 702, Name: "Singapore" },
    { Id: 703, Name: "Slovakia" },
    { Id: 704, Name: "Viet Nam" },
    { Id: 705, Name: "Slovenia" },
    { Id: 706, Name: "Somalia" },
    { Id: 710, Name: "South Africa" },
    { Id: 716, Name: "Zimbabwe" },
    { Id: 724, Name: "Spain" },
    { Id: 728, Name: "South Sudan" },
    { Id: 732, Name: "Western Sahara" },
    { Id: 736, Name: "Sudan" },
    { Id: 740, Name: "Suriname" },
    { Id: 744, Name: "Svalbard and Jan Mayen Islands" },
    { Id: 748, Name: "Swaziland" },
    { Id: 752, Name: "Sweden" },
    { Id: 756, Name: "Switzerland" },
    { Id: 760, Name: "Syrian Arab Republic (Syria)" },
    { Id: 762, Name: "Tajikistan" },
    { Id: 764, Name: "Thailand" },
    { Id: 768, Name: "Togo" },
    { Id: 772, Name: "Tokelau" },
    { Id: 776, Name: "Tonga" },
    { Id: 780, Name: "Trinidad and Tobago" },
    { Id: 784, Name: "United Arab Emirates" },
    { Id: 788, Name: "Tunisia" },
    { Id: 792, Name: "Turkey" },
    { Id: 795, Name: "Turkmenistan" },
    { Id: 796, Name: "Turks and Caicos Islands" },
    { Id: 798, Name: "Tuvalu" },
    { Id: 800, Name: "Uganda" },
    { Id: 804, Name: "Ukraine" },
    { Id: 807, Name: "Macedonia, Republic of" },
    { Id: 818, Name: "Equatorial Guinea" },
    { Id: 826, Name: "United Kingdom" },
    { Id: 831, Name: "Guinea" },
    { Id: 832, Name: "Jordan" },
    { Id: 833, Name: "Israel" },
    { Id: 834, Name: "Tanzania, United Republic of" },
    { Id: 840, Name: "United States" },
    { Id: 850, Name: "Virgin Islands, US" },
    { Id: 854, Name: "Burkina Faso" },
    { Id: 858, Name: "Uruguay" },
    { Id: 860, Name: "Uzbekistan" },
    { Id: 862, Name: "Venezuela (Bolivarian Republic)" },
    { Id: 876, Name: "Wallis and Futuna Islands" },
    { Id: 882, Name: "Samoa" },
    { Id: 887, Name: "Yemen" },
    { Id: 894, Name: "Zambia" }
]
