import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyPopupService {

  constructor() { }

  public showCompanyPopup$ = new Subject<any>();

  showCompanyPopup(data) {
    this.showCompanyPopup$.next(data);
  }
}
