import { Injectable } from '@angular/core';
import { HttpBaseService } from '../httpbase.service';
import { Observable } from 'rxjs';
import { JustificationReason, ReorderJustificationReason } from '@app/shared/models/config/justification-reason.model';

@Injectable({
  providedIn: 'root'
})
export class JustificationReasonService {

  constructor(private httpBaseService: HttpBaseService) { }

  public getJustificationReasons(userId): Observable<any> {
    const apiUrl = 'JustificationReason/GetJustificationReasons/'+ userId
    return this.httpBaseService.get(apiUrl);
  }
  createJustificationReason(justificationReason: JustificationReason): Observable<any> {
    return this.httpBaseService.post('JustificationReason/CreateJustificationReason', justificationReason);
  }
  updateJustificationReason(justificationReason: JustificationReason): Observable<any> {
    return this.httpBaseService.post('JustificationReason/UpdateJustificationReason', justificationReason);
  }
  deleteJustificationReason(justificationReason: JustificationReason): Observable<any> {
    return this.httpBaseService.post('JustificationReason/DeleteJustificationReason', justificationReason);
  }
  reorderJustificationReason(justificationReason: ReorderJustificationReason): Observable<any> {
    return this.httpBaseService.post('JustificationReason/ReorderJustificationReasons', justificationReason);
  }
}
