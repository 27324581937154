import { Component, Injector, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyPopupService } from '@app/shared/common/company-popup/company-popup.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import CustomStore from 'devextreme/data/custom_store';
import { CompanyCertificateIssuanceService } from '../shared/services/company-certificate-issuance/company-certificate-issuance.service';

@Component({
  selector: 'app-certificate-grid',
  templateUrl: './certificate-grid.component.html',
  styleUrls: ['./certificate-grid.component.css']
})
export class CertificateGridComponent extends AppComponentBase implements OnInit {

  // @Input('companyCertificateIssuanceList') companyCertificateIssuanceList;
  @Input('clientId') clientId;
  @Input('companyId') companyId;
  searchString = '';
  companyCertificateIssuanceList: any = [];

  constructor(private injector: Injector,
    private router: Router,
    private companyPopup: CompanyPopupService,
    private companyCertificateIssuanceService: CompanyCertificateIssuanceService) { super(injector); }

  ngOnInit(): void {
    this.getCompanyCertificateIssuanceList();
  }

  editCompanyCertificateIssuance = (e) => {
    if (this.clientId) {
      this.router.navigate([`app/certificate/edit/${this.clientId}/${e.row.data.id}`]);
    }
    else {
      this.router.navigate([`app/certificate/edit/${e.row.data.id}`]);
    }
  }

  deleteCompanyCertificateIssuance = (e) => {
    var self = this;
    var deleteCompanyCertificateIssuance = e.row.data;
    deleteCompanyCertificateIssuance.deleterUserId = this.appSession.user.id;
    deleteCompanyCertificateIssuance.deletionTime = String(new Date());
    deleteCompanyCertificateIssuance.isDeleted = true;
    abp.message.confirm(
      'Certificate will be deleted.',
      'Are you sure?',
      function (isConfirmed) {
        if (isConfirmed) {
          self.companyCertificateIssuanceService.deleteCompanyCertificateIssuance(deleteCompanyCertificateIssuance).subscribe(
            (data: any) => {
              var result = data;
              abp.notify.success("", "Certificate deleted successfully", { showCloseButton: true, timer: 10000 });
              self.getCompanyCertificateIssuanceList();
            }, (error: any) => {
              abp.log.error(error);
              abp.notify.error("", "Failed to delete Certificate", { showCloseButton: true, timer: 10000 });
            }
          )
        }
      }
    );

  }

  viewCompanyCertificateIssuance = (id) => {
    if (this.clientId) {
      this.router.navigate([`app/certificate/edit/${this.clientId}/${id}`]);
    }
    else {
      this.router.navigate([`app/certificate/edit/${id}`]);
    }
  }


  getCompanyCertificateIssuanceList() {
    var self = this;
    this.companyCertificateIssuanceList = new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        const searchString = loadOptions.filter ? (loadOptions.filter[0].filterValue + '').trim() : ""
        let dto = {
          searchString: searchString,
          pageSize: loadOptions.take,
          pageNumber: (self.searchString != searchString) ? 1 : (loadOptions.skip / loadOptions.take) + 1,
          userId: self.appSession.user.id
        }
        let searchDto;
        if (self.clientId) {
          searchDto = {
            ...dto,
            clientId: self.clientId
          }
        }
        else if(self.companyId){
          searchDto = {
            ...dto,
            clientId: self.companyId, //Todo: update this when API is available
            companyId: self.companyId
          }
        }
        else {
          searchDto = dto;
        }
        self.spinnerService.show();
        self.searchString = searchDto.searchString;

        return self.companyCertificateIssuanceService.getCompanyCertificateIssuanceListUsingServerSidePagination(searchDto)
          .toPromise()
          .then((data: any) => {
            self.spinnerService.hide();
            if (!self.clientId) {

            }
            return {
              data: data.certificateList ? data.certificateList : [],
              totalCount: data.totalItemsCount
            };
          })
          .catch(error => {
            self.spinnerService.hide();
            abp.log.error(error);
            abp.notify.error("", "Failed to retrieve certificate list", { showCloseButton: true, timer: 10000 });
          });
      }
    });
  }
  openClientPopUp(clientId) {
    this.companyPopup.showCompanyPopup(clientId);
  }

  onToolbarPreparing(e) {
    // e.toolbarOptions.items.push({
    //   location: 'after',
    //   widget: 'dxButton',
    //   options: {
    //     icon: "fa fa-plus",
    //     text: 'Add',
    //     onClick: this.addCompanyCertificateIssuance.bind(this)
    //   }
    // });
  }

  addCompanyCertificateIssuance() {
    if (this.clientId) {
      this.router.navigate([`app/certificate/add/${this.clientId}`]);
    }
    else {
      this.router.navigate([`app/certificate/add`]);
    }

  }
}
