<p-sidebar class="sidebar-60" [(visible)]="showSideBar" (onHide)="close()">
    <form [formGroup]="justificationForm" novalidate (ngSubmit)="save()">
        <div class="sidebar-header-wrapper">
            <h4>
                <span>{{modalTitle}}&nbsp;Justification</span>
            </h4>
        </div>
        <div class="sidebar-body">
            <div class="row">
                <div class="col-md-6">

                    <div class="form-group">
                        <label>Standard Category
                        </label>
                        <ng-select [selectOnTab]="true" formControlName="rStandardCategory_Id">
                            <ng-container *ngFor="let c of standardCategoryList">
                                <ng-option *ngIf="c.isDeleted === false" [value]="c.id">{{c.name}}</ng-option>
                            </ng-container>
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Total Percentage
                        </label>
                        <input class="form-control" type="number" name="name" disabled
                            [ngModel]="getTotalPercentage()" [ngModelOptions]="{standalone: true}">
                    </div>
                </div>
                <div class="col-xl-6">
                    <h5>Reasons</h5>
                    <div class="form-group">
                        <div *ngFor="let item of reasonProcessedList; let i = index" class="reason-item">
                            <label class="reason-label mb-0">{{item.name}}</label>
                            <div class="input-group">
                                <input class="form-control percent-input" type="number" name="name"
                                    [(ngModel)]="item.justificationPercent" [ngModelOptions]="{standalone: true}">
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-6">
                    <div class="row">
                        <div class="col-lg-6 col-xl-12">
                            <div class="form-group">
                                <label>Explanation</label>
                                <textarea rows="5" type="text" class="form-control total-input"
                                    formControlName="explanation"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-12">
                            <div class="select-file-wrap form-group">
                                <label for="auditor" class="text-nowrap font-weight-bold">Evidence</label>
                                <div class="custom-file file-content" *ngIf="!fileLoading">
                                    <input type="file" class="custom-file-input" accept=".xls,.xlsx"
                                        (change)="onFileChange($event)" multiple="false" id="customFile">
                                    <label class="custom-file-label" for="customFile">{{fileName || "Choose file"
                                        }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <!-- [disabled]="!justificationForm.dirty" -->
            <button type="submit" class="btn primary-button">
                <span>{{modalTitle == 'Add' ? 'Save':'Update'}}</span></button>
            <button type="button" class="btn secondary-button" (click)="close()">Cancel</button>
        </div>
    </form>
</p-sidebar>
