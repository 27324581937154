import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { DataDictionaryService } from '@app/shared/services/data-dictionary/data-dictionary.service';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'app-organization-code',
  templateUrl: './organization-code.component.html',
  styleUrls: ['./organization-code.component.css']
})
export class OrganizationCodeComponent extends AppComponentBase implements OnInit {


  @Input('organizationList') organizationCodes = [
    { ouName: "NTS (ALL)", ouId: 1 },
    { ouName: "NQA-UK", ouId: 3 },
    { ouName: "NQA-US", ouId: 4 }
  ];
  @Input('organizationCodes') selectedOrganizationCodes;
  @Output('selectedCodes') selectedCodes = new EventEmitter();
  constructor(private injector: Injector, private dataDictionaryService: DataDictionaryService) {
    super(injector);
  }


  ngOnInit(): void {
    //  this.getOrganizationUnits();
  }

  getOrganizationUnits() {
    this.dataDictionaryService.getUserOrganizationUnits(this.appSession.user.id).subscribe(
      (data: any) => {
        this.organizationCodes = data;
      }, (error: any) => {
        abp.log.error(error);
        abp.notify.error("", "Failed to load organization units", { showCloseButton: true, timer: 10000 });
      }
    )
  }



  onOrganizationChange(e) {
    if (e.itemValue) {
      this.selectedOrganizationCodes = [e.itemValue];
    }
    this.selectedCodes.emit(this.selectedOrganizationCodes as any);
  }

}
