import { Injectable } from '@angular/core';
import { HttpBaseService } from '@app/shared/services/httpbase.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyCertificateIssuanceService {

  constructor(private httpBaseService: HttpBaseService) { }


  public getCompanyCertificateIssuanceListUsingServerSidePagination(payload): Observable<any> {
    const apiUrl = 'CompanyCertificateIssuance/GetCertificateIssuanceListUsingServerSidePagination';
    return this.httpBaseService.post(apiUrl, payload)
  }

  public getCompanyCertificateIssuanceConsolidatedById(id, userId): Observable<any> {
    const apiUrl = 'CompanyCertificateIssuance/GetCompanyCertificateIssuanceConsolidatedDetailsById/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public createCompanyCertificateIssuance(data): Observable<any> {
    const apiUrl = 'CompanyCertificateIssuance/CreateCompanyCertificateIssuanceConsolidated'
    return this.httpBaseService.post(apiUrl, data);
  }

  public updateCompanyCertificateIssuance(data): Observable<any> {
    const apiUrl = 'CompanyCertificateIssuance/UpdateCompanyCertificateIssuance'
    return this.httpBaseService.post(apiUrl, data);
  }

  public deleteCompanyCertificateIssuance(data): Observable<any> {
    const apiUrl = 'CompanyCertificateIssuance/DeleteCompanyCertificateIssuance'
    return this.httpBaseService.post(apiUrl, data);
  }

  //Suspension History

  public getCompanyCertificateIssuanceSuspensionHistoriesByCertificateId(id, userId): Observable<any> {
    const apiUrl = 'CompanyCertificateIssuanceSuspensionHistory/GetCompanyCertificateIssuanceSuspensionHistoriesByCertificateId/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public createCompanyCertificateIssuanceSuspensionHistory(data): Observable<any> {
    const apiUrl = 'CompanyCertificateIssuanceSuspensionHistory/CreateCompanyCertificateIssuanceSuspensionHistory'
    return this.httpBaseService.post(apiUrl, data);
  }

  public updateCompanyCertificateIssuanceSuspensionHistory(data): Observable<any> {
    const apiUrl = 'CompanyCertificateIssuanceSuspensionHistory/UpdateCompanyCertificateIssuanceSuspensionHistory'
    return this.httpBaseService.post(apiUrl, data);
  }

  //Multi-site
  public makeHQ(data): Observable<any> {
    const apiUrl = 'CompanyCertificateIssuanceCertificationStructure/MakeHQ'
    return this.httpBaseService.post(apiUrl, data);
  }

  public makeChild(data): Observable<any> {
    const apiUrl = 'CompanyCertificateIssuanceCertificationStructure/MakeChild'
    return this.httpBaseService.post(apiUrl, data);
  }

  public deleteCompanyCertificateIssuanceCertificationStructure(data): Observable<any> {
    const apiUrl = 'CompanyCertificateIssuanceCertificationStructure/DeleteCompanyCertificateIssuanceCertificationStructure'
    return this.httpBaseService.post(apiUrl, data);
  }

  public getMultiSiteStructureByCertificateId(certificateId, userId): Observable<any> {
    const apiUrl = 'CompanyCertificateIssuance/GetMultiSiteStructureByCertificateId/' + certificateId + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
}
