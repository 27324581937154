import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-quotes-to-complete',
  templateUrl: './widget-quotes-to-complete.component.html',
  styleUrls: ['./widget-quotes-to-complete.component.css']
})
export class WidgetQuotesToCompleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
