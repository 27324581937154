import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import ODataStore from 'devextreme/data/odata/store';
import { LoadOptions } from 'devextreme/data/load_options';

@Injectable({
    providedIn: 'root'
})
export class WidgetService {

    store: ODataStore;

    constructor() { }

    //getFinanceRevenueSummary(): Observable<any> {
        // this.store = new ODataStore({
        //     url: environment.odataBaseUrl + 'GetRevenueSummary',
        //     version: 4,
        //     withCredentials: true,
        //     key: 'Id',
        //     keyType: 'Int64',

        //     beforeSend: (e) => {
        //         e.method = "get",
        //             e.headers = {
        //                 "x-cdata-authtoken": environment.odataKey
        //             }//,
        //         // e.params = {
        //         //     "UserId": abp.session.userId
        //         // }
        //     }
        //     // Other ODataStore properties go here
        // });
        // let options: LoadOptions = {
        //     //filter: [ "CompanyName", "=", "ABC Company" ]
        //     // Data processing settings are specified here
        //     //sort: ["LastName", "FirstName"]
        // };
        // let returnData: any;
        // this.store.load(options)
        //     .then(
        //         (data) => {
        //             console.log(data);
        //             returnData = data;
        //         },
        //         (error) => {
        //             console.log(error);
        //         }
        //     );
        // return returnData;
    //}
}
