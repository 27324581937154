import { Injectable } from '@angular/core';
import { HttpBaseService } from '@app/shared/services/httpbase.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContractReviewService {

  constructor(private httpBaseService: HttpBaseService) { }

  public getContractReviewConsolidatedById(id, userId): Observable<any> {
    const apiUrl = 'ContractReview/GetContractReviewConsolidatedById/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
  public getMultiSiteStructures(userId): Observable<any> {
    const apiUrl = 'MultiSiteStructure/GetMultiSiteStructures/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
  public getBusinessTypes(userId): Observable<any> {
    const apiUrl = 'BusinessType/GetBusinessTypes/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
  public createContractReviewIndustryCode(payload): Observable<any> {
    const apiUrl = 'ContractReviewIndustryCode/CreateContractReviewIndustryCode'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public deleteContractReviewIndustryCode(payload): Observable<any> {
    const apiUrl = 'ContractReviewIndustryCode/DeleteContractReviewIndustryCode'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public getContractReviewIndustryCodesByContractReviewId(id, userId): Observable<any> {
    const apiUrl = 'ContractReviewIndustryCode/GetContractReviewIndustryCodesByContractReviewId/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public createContractReviewStandardNonApplicableClause(payload): Observable<any> {
    const apiUrl = 'ContractReviewStandardNonApplicableClause/CreateContractReviewStandardNonApplicableClause'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public deleteContractReviewStandardNonApplicableClause(payload): Observable<any> {
    const apiUrl = 'ContractReviewStandardNonApplicableClause/DeleteContractReviewStandardNonApplicableClause'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public getContractReviewSystemScopeGroupsByContractReviewId(id, userId): Observable<any> {
    const apiUrl = 'ContractReviewSystemScopeGroup/GetContractReviewSystemScopeGroupsByContractReviewId/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }


  public createContractReviewTransferInfo(payload): Observable<any> {
    const apiUrl = 'ContractReviewTransferInfo/CreateContractReviewTransferInfo'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public updateContractReviewTransferInfo(payload): Observable<any> {
    const apiUrl = 'ContractReviewTransferInfo/UpdateContractReviewTransferInfo'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public deleteContractReviewTransferInfo(payload): Observable<any> {
    const apiUrl = 'ContractReviewTransferInfo/DeleteContractReviewTransferInfo'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public getContractReviewTransferInfoById(id, userId): Observable<any> {
    const apiUrl = 'ContractReviewTransferInfo/GetContractReviewTransferInfoById/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
  public getContractReviewTransferInfosByContractReviewId(id, userId): Observable<any> {
    const apiUrl = 'ContractReviewTransferInfo/GetContractReviewTransferInfosByContractReviewId/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }


  public createContractReviewTransferInfoPreviousCertificationDetail(payload): Observable<any> {
    const apiUrl = 'ContractReviewTransferInfoPreviousCertificationDetail/CreateContractReviewTransferInfoPreviousCertificationDetail'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public updateContractReviewTransferInfoPreviousCertificationDetail(payload): Observable<any> {
    const apiUrl = 'ContractReviewTransferInfoPreviousCertificationDetail/UpdateContractReviewTransferInfoPreviousCertificationDetail'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public deleteContractReviewTransferInfoPreviousCertificationDetail(payload): Observable<any> {
    const apiUrl = 'ContractReviewTransferInfoPreviousCertificationDetail/DeleteContractReviewTransferInfoPreviousCertificationDetail'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public getContractReviewTransferInfoPreviousCertificationDetailsByContractReviewId(id, userId): Observable<any> {
    const apiUrl = 'ContractReviewTransferInfoPreviousCertificationDetail/GetContractReviewTransferInfoPreviousCertificationDetailsByContractReviewId/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public createContractReviewTransferInfoTransferDocument(payload): Observable<any> {
    const apiUrl = 'ContractReviewTransferInfoTransferDocument/CreateContractReviewTransferInfoTransferDocument'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public updateContractReviewTransferInfoTransferDocument(payload): Observable<any> {
    const apiUrl = 'ContractReviewTransferInfoTransferDocument/UpdateContractReviewTransferInfoTransferDocument'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public deleteContractReviewTransferInfoTransferDocument(payload): Observable<any> {
    const apiUrl = 'ContractReviewTransferInfoTransferDocument/DeleteContractReviewTransferInfoTransferDocument'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public getContractReviewTransferInfoTransferDocumentsByContractReviewId(id, userId): Observable<any> {
    const apiUrl = 'ContractReviewTransferInfoTransferDocument/GetContractReviewTransferInfoTransferDocumentsByContractReviewId/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public createContractReviewStandardGroup(payload): Observable<any> {
    const apiUrl = 'ContractReviewStandardGroup/CreateContractReviewStandardGroup'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public updateContractReviewStandardGroup(payload): Observable<any> {
    const apiUrl = 'ContractReviewStandardGroup/UpdateContractReviewStandardGroup'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public deleteContractReviewStandardGroup(payload): Observable<any> {
    const apiUrl = 'ContractReviewStandardGroup/DeleteContractReviewStandardGroup'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public getContractReviewStandardGroupsByContractReviewId(id, userId): Observable<any> {
    const apiUrl = 'ContractReviewStandardGroup/GetContractReviewStandardGroupsByContractReviewId/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public getContractReviewStandardGroupTypes(userId): Observable<any> {
    const apiUrl = 'AuditPlanType/GetAuditPlanTypes/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public createContractReviewStandardGroupLocation(payload): Observable<any> {
    const apiUrl = 'ContractReviewStandardGroupLocation/CreateContractReviewStandardGroupLocation'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public updateContractReviewStandardGroupLocation(payload): Observable<any> {
    const apiUrl = 'ContractReviewStandardGroupLocation/UpdateContractReviewStandardGroupLocation'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public deleteContractReviewStandardGroupLocation(payload): Observable<any> {
    const apiUrl = 'ContractReviewStandardGroupLocation/DeleteContractReviewStandardGroupLocation'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public getContractReviewStandardGroupLocationsByContractReviewId(id, userId): Observable<any> {
    const apiUrl = 'ContractReviewStandardGroupLocation/GetContractReviewStandardGroupLocationsByContractReviewId/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
  public getContractReviewStandardGroupLocationById(id, userId): Observable<any> {
    const apiUrl = 'ContractReviewStandardGroupLocation/GetContractReviewStandardGroupLocationById/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
  public getPersonListExcludingCompanySecondaryCsr(companyId, userId): Observable<any> {
    const apiUrl = 'Person/GetPersonListExcludingCompanySecondaryCsr/' + companyId + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public UpdateContractReviewClientInfo(payload): Observable<any> {
    const apiUrl = 'ContractReview/UpdateContractReviewClientInfo'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public getContractReviewCheckListById(id, userId): Observable<any> {
    const apiUrl = 'ContractReviewCheckList/GetContractReviewCheckListByContractReviewId/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public getContractReviewCheckListStatuses(userId): Observable<any> {
    const apiUrl = 'ContractReviewCheckListStatus/GetContractReviewCheckListStatuses/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public getContractReviewStandardsLegalRequirementsStatuses(userId): Observable<any> {
    const apiUrl = 'LegalRequirementsStatus/GetLegalRequirementsStatuses/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public createOrUpdateContractReviewLegalRequirements(payload): Observable<any> {
    const apiUrl = 'ContractReviewStandardGroupLocationsAndAssociatedStandardsLegalRequirement/CreateOrUpdateContractReviewLegalRequirements'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public getContractReviewListUsingServerSidePagination(payload): Observable<any> {
    const apiUrl = 'ContractReview/GetContractReviewListUsingServerSidePagination';
    return this.httpBaseService.post(apiUrl, payload)
  }

  public deleteContractReview(data): Observable<any> {
    const apiUrl = 'ContractReview/DeleteContractReview'
    return this.httpBaseService.post(apiUrl, data);
  }

  public createContractReviewAuditType(data): Observable<any> {
    const apiUrl = 'ContractReviewStandardGroupLocationsAndAuditType/CreateContractReviewStandardGroupLocationsAndAuditType'
    return this.httpBaseService.post(apiUrl, data);
  }

  public updateContractReviewAuditType(data): Observable<any> {
    const apiUrl = 'ContractReviewStandardGroupLocationsAndAuditType/UpdateContractReviewStandardGroupLocationsAndAuditType'
    return this.httpBaseService.post(apiUrl, data);
  }

  public deleteContractReviewStandardGroupLocationsAndAuditType(data): Observable<any> {
    const apiUrl = 'ContractReviewStandardGroupLocationsAndAuditType/DeleteContractReviewStandardGroupLocationsAndAuditType'
    return this.httpBaseService.post(apiUrl, data);
  }

  public deleteContractReviewStandardGroupLocationsAndAuditTypes(data): Observable<any> {
    const apiUrl = 'ContractReviewStandardGroupLocationsAndAuditType/DeleteContractReviewStandardGroupLocationsAndAuditTypes'
    return this.httpBaseService.post(apiUrl, data);
  }

  public getContractReviewAuditTypesByContractReviewId(id, userId): Observable<any> {
    const apiUrl = 'ContractReviewStandardGroupLocationsAndAuditType/GetContractReviewStandardGroupLocationsAndAuditTypesByContractReviewId/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public getContractReviewStatuses(userId): Observable<any> {
    const apiUrl = 'ContractReviewStatus/GetContractReviewStatuses/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public updateContractReviewStatus(contractReviewId, contractReviewStatusId, userId): Observable<any> {
    const apiUrl = 'ContractReview/UpdateContractReviewStatus/'+ contractReviewId + '/'+ contractReviewStatusId + '/' + userId;
    return this.httpBaseService.post(apiUrl, null);
  }
}
