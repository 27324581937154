import { Injectable } from '@angular/core';
import { HttpBaseService } from '../httpbase.service';
import { Observable } from 'rxjs';
import { CompanyStatus, ReorderCompanyStatus } from '@app/shared/models/config/company-status.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyStatusService {

  constructor(private httpBaseService: HttpBaseService) { }

  public getAllCompanyStatus(userId): Observable<any> {
    const apiUrl = 'CompanyStatus/GetCompanyStatuses/' + userId
    return this.httpBaseService.get(apiUrl);
  }

  createCompanyStatus(companyStatus: CompanyStatus): Observable<any> {
    return this.httpBaseService.post('CompanyStatus/CreateCompanyStatus', companyStatus);
  }
  updateCompanyStatus(companyStatus: CompanyStatus): Observable<any> {
    return this.httpBaseService.post('CompanyStatus/UpdateCompanyStatus', companyStatus);
  }
  deleteCompanyStatus(companyStatus: CompanyStatus): Observable<any> {
    return this.httpBaseService.post('CompanyStatus/DeleteCompanyStatus', companyStatus);
  }
  reorderCompanyStatus(companyStatus: ReorderCompanyStatus): Observable<any> {
    return this.httpBaseService.post('CompanyStatus/ReorderCompanyStatuses', companyStatus);
  }

}
