import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { ChecklistTypeService } from '@app/shared/services/checklist-type/checklist-type.service';
import { DataDictionaryService } from '@app/shared/services/data-dictionary/data-dictionary.service';
import { JustificationService } from '@app/shared/services/justification/justification.service';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { JustificationModalComponent } from '../justification-modal/justification-modal.component';

@Component({
  selector: 'app-justification-grid',
  templateUrl: './justification-grid.component.html',
  styleUrls: ['./justification-grid.component.css']
})
export class JustificationGridComponent extends AppComponentBase implements OnInit {

  @Input('ownerId') ownerId;
  @Input('module') module;
  @Input('isRecordReadOnly') isRecordReadOnly: boolean = false;
  @ViewChild('configModal') configModal: JustificationModalComponent;
  typeList: any = [];

  constructor(private injector: Injector, private dataDictionaryService: DataDictionaryService,
    private justificationService: JustificationService,
    private dateTimeService: DateTimeService) { super(injector); }

  ngOnInit(): void {
    if (this.ownerId) {
      this.getJustificationsByOwnerId();
    }
  }


  addType(e) {
    this.configModal.show(0);
  }

  saveType(e) {
    this.getJustificationsByOwnerId();
  }

  editType = (e) => {
    this.configModal.show(JSON.parse(JSON.stringify((e.row.data))));
  }

  deleteType = (e) => {
    var self = this;
    e.row.data.isDeleted = true;
    e.row.data.deleterUserId = this.appSession.user.id;
    e.row.data.deletionTime = this.dateTimeService.formatDate(this.dateTimeService.toUtcDate(new Date()), AppConsts.DateFormat);

    abp.message.confirm(
      'Justification will be deleted.',
      'Are you sure?',
      function (isConfirmed) {
        if (isConfirmed) {
          self.spinnerService.show();
          self.justificationService.deleteJustification(e.row.data).subscribe(
            (data: any) => {
              self.spinnerService.hide();
              abp.notify.success("", "Justification deleted successfully", { showCloseButton: true, timer: 10000 });
              self.getJustificationsByOwnerId();
            }, (error: any) => {

              self.spinnerService.hide();
              abp.log.error(error);
              abp.notify.error("", "Failed to delete justification", { showCloseButton: true, timer: 10000 });
            }
          )
        }
      }
    );
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: "fa fa-plus",
        text: 'Add',
        onClick: this.addType.bind(this),
        disabled: this.isRecordReadOnly,
      }
    });
  }

  getJustificationsByOwnerId() {
    this.spinnerService.show();

    let service = this.justificationService.getJustificationsByOwnerId(this.ownerId, this.appSession.user.id);

    if (this.module && this.module == AppConsts.Module.Client) {
      service = this.justificationService.getJustificationsByClientId(this.ownerId, this.appSession.user.id);
    }

    service.subscribe(
      (data: any) => {
        this.spinnerService.hide();
        this.typeList = data;
      }, (error: any) => {

        this.spinnerService.hide();
        abp.log.error(error);
        abp.notify.error("", "Failed to load justifications", { showCloseButton: true, timer: 10000 });
      }
    )
  }
}





