import { ClientTab } from "./AppEnums";

export class AppConsts {

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'BlueOcean'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };

    static readonly grid = {
        defaultPageSize: 10
    };

    static readonly MinimumUpgradePaymentAmount = 1;

    /// <summary>
    /// Gets current version of the application.
    /// It's also shown in the web page.
    /// </summary>
    static readonly WebAppGuiVersion = '10.1.0';

    static readonly localStorageKeys = {
        isNavigatedFromAddEditCompany: "isNavigatedFromAddEditCompany",
        isNavigatedFromAddEditPerson: "isNavigatedFromAddEditPerson",
        isNavigatedFromEditAuditor: "isNavigatedFromEditAuditor",
        isNavigatedFromEditContact: "isNavigatedFromEditContact",
        companyDisplayAllLocationFilter: "companyDisplayAllLocationFilter",
        isNavigatedFromAuditViewOrEdit: 'isNavigatedFromAuditViewOrEdit',
        companyListStorageName: "companyListStorageName",
        personListStorageName: "personListStorageName",
        auditorListStorageName: "auditorListStorageName",
        contactListStorageName: "contactListStorageName",
        auditListStorageName: "auditListStorageName",
        auditListAuditType: "auditListAuditType",
        previousAuditListStorageName: "previousAuditListStorageName",
        personModuleFromPageKey: "fromPage",
        scheduleDetailsFromPageKey: "schduleFromPage",
        navigatedToPersonModuleFromCompany: "navigatedToPersonModuleFromCompany",
        navigatedToPersonModuleFromClient: "navigatedToPersonModuleFromClient",
        clientListStorageName: "clientListStorageName",
        isNavigatedFromAddEditClient: "isNavigatedFromAddEditClient",
        clientModuleFromPageKey: "clientFromPage",
        ClientContactTabKey: "clientContactTab",

        contactDetailFromPageKey: "contactDetailFromPage",
        quoteDetailFromPageKey: "quoteDetailFromPage",

        navigatedFromQuotesId: "navigatedFromQuotesModuleId",
        navigatedFromQuotesClientId: "navigatedFromQuotesClientId",
        navigatedFromQuotesTab: "navigatedFromQuotesTab",
        navigatedFromQuotesTabObj: "navigatedFromQuotesTabObj",

        navigatedFromContractReviewClientId: "navigatedFromContractReviewClientId",
        navigatedFromContractReviewId: "navigatedFromContractReviewId",
        navigatedFromContractReviewStep: "navigatedFromContractReviewStep",
        navigatedFromContractReviewTab: "navigatedFromContractReviewTab"
    }

    static readonly MultipleCompanies = 'Multiple Companies';
    static readonly NoAssociatedCompany = 'Not associated to a company';
    static readonly SalesRepId = 4000000000;
    static readonly PrimaryText = 'Primary';
    static readonly DateFormat = 'MM/dd/yyyy HH:mm:ss';
    static readonly SchedulerDateFormat = 'MM/dd/yyyy 08=00=00';
    static readonly DateFormatWithoutTime = 'MM/dd/yyyy';
    static readonly booleanTrueValue = "true";
    static readonly booleanFalseValue = "false";
    static readonly momentDateOnly = 'MM/DD/yyyy';
    static readonly CountryCodeUSA = 'USA';
    static readonly CountryCodeCanada = 'CAN';
    static readonly CompanyContactTabNumber = 8;
    static readonly ClientContactTabNumber = 2;
    static readonly MainAddressExists = "Warning! Main Address Exists";
    static readonly SelectAddressType = "Select Address Type";
    static readonly SelectParentEntity = "Select Parent Entity";
    static readonly MainAddressChange = "Warning! Main Address Change";
    static readonly SelectMainAddress = "Select Main Address";
    static readonly FeatureNameAuditEventSheet = "Audit Event Sheet";

    static readonly auditConductedReviewStatuses = {
        completed: "Completed",
        inProgress: "In Progress",
        rejected: "Rejected",
    }

    static readonly QuoteStatuses = {
        New: "New",
        Open: "Open",
        Draft: "Draft",
    }

    static readonly CertificateStatuses = {
        Applicant: "Applicant",
        Suspended: "Suspended"
    }

    static readonly AuditType = {
        TransferSurveillance: "Transfer/Surveillance",
        NoVisitTransfer: "No Visit Transfer",
        Stage1Audit: "Stage 1",
        Stage2Audit: "Stage 2",
        Surveillance: "Surveillance",
        Reassessment: "Reassessment",
        TransferReassessment: "Transfer/Reassessment",
        MigrationSA: "Migration Surveillance",
        MigrationRA: "Migration Reassessment",
        SpecialVisit: "Special Visit",
        GapAnalysis: "Pre-Assessment",
        Assessment: "Assessment",

    }

    static readonly ContactType = {
        Primary: "Primary Contact",
        Secondary: "Secondary Contact"
    }

    static readonly ChecklistSection = {
        Question: 8760000000,
        Recommendation: 8760000001
    }

    static readonly RuleEngineModule = {
        Quote: "Quote",
        ContractReview: "ContractReview",
        Audit: "Audit",
        Checklist: "Checklist"
    }

    static readonly Module = {
        Client: "Client",
        Quote: "Quote",
        ContractReview: "ContractReview",
        Audit: "Audit",
        Checklist: "Checklist"
    }

    static readonly AuditPlanIntegrated = "Integrated";
    static readonly AuditPlanStandalone = "Standard";

    static readonly ContractReviewStatusComplete = "Complete";
    static readonly ContractReviewStatusIncomplete = "Incomplete/Missing";
    static readonly ContractReviewStatusNotRequired = "Not required";

    static getFiscalYearList(): any[] {
        var fiscalYears = [];
        let today = new Date();
        let currentYear = today.getFullYear();
        fiscalYears.push({ id: currentYear - 1, name: currentYear - 1 });
        let prevYear = today.getFullYear() - 1;
        for (let i = currentYear; i < currentYear + 4; i++) {
            fiscalYears.push({ id: i, name: i });
        }
        return fiscalYears;
    }

}
