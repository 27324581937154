<ul class="menu-nav quick-access-menu">
    <li class="menu-item" [ngClass]="{'menu-item-open': isQuickAccessOpen}" aria-haspopup="true" data-menu-toggle="hover">
        <a class="menu-link menu-toggle" (click)="toggleQuickAccess()">
            <span class="menu-icon">
                <i class="menu-link-icon fal fa-square-list"></i>
            </span>
            <span class="menu-text">Quick Access</span>
            <span class="menu-arrow"></span>
        </a>
        <div class="menu-submenu" [ngClass]="{'menu-submenu-open': isQuickAccessOpen}">
            <ul class="menu-subnav">
                <li class="menu-item" (click)="companyClick()" type="button">
                    <div class="menu-link">
                        <span class="menu-icon">
                            <i class="menu-link-icon fal fa-building"></i>
                        </span>
                        <span class="menu-text">Companies</span>
                    </div>
                </li>
                <li class="menu-item" (click)="clientClick()" type="button">
                    <div class="menu-link">
                        <span class="menu-icon">
                            <i class="menu-link-icon fal fa-users-crown"></i>
                        </span>
                        <span class="menu-text">Clients</span>
                    </div>
                </li>
                <li class="menu-item" (click)="peopleClick()" type="button">
                    <div class="menu-link">
                        <span class="menu-icon">
                            <i class="menu-link-icon fal fa-users"></i>
                        </span>
                        <span class="menu-text">People</span>
                    </div>
                </li>
                <li class="menu-item" (click)="contactClick()" type="button">
                    <div class="menu-link">
                        <span class="menu-icon">
                            <i class="menu-link-icon fal fa-id-card-alt"></i>
                        </span>
                        <span class="menu-text">Contacts</span>
                    </div>
                </li>
                <li class="menu-item" (click)="auditorClick()" type="button">
                    <div class="menu-link">
                        <span class="menu-icon">
                            <i class="menu-link-icon fal fa-users-class"></i>
                        </span>
                        <span class="menu-text">Auditors</span>
                    </div>
                </li>
                <li class="menu-item" (click)="dataDictionariesClick()" type="button">
                    <div class="menu-link">
                        <span class="menu-icon">
                            <i class="menu-link-icon fal fa-cogs"></i>
                        </span>
                        <span class="menu-text">Data Dictionaries</span>
                    </div>
                </li>
                <li class="menu-item" (click)="quoteClick()" type="button">
                    <div class="menu-link">
                        <span class="menu-icon">
                            <i class="menu-link-icon fal fa-file-invoice-dollar"></i>
                        </span>
                        <span class="menu-text">Quotes</span>
                    </div>
                </li>
                <li class="menu-item" (click)="contractReviewClick()" type="button">
                    <div class="menu-link">
                        <span class="menu-icon">
                            <i class="menu-link-icon fal fa-file-alt"></i>
                        </span>
                        <span class="menu-text">Contract Reviews</span>
                    </div>
                </li>
                <li class="menu-item" (click)="auditClick()" type="button">
                    <div class="menu-link">
                        <span class="menu-icon">
                            <i class="menu-link-icon fal fa-search-location"></i>
                        </span>
                        <span class="menu-text">Audits</span>
                    </div>
                </li>
                <li class="menu-item" (click)="ncrClick()" type="button">
                    <div class="menu-link">
                        <span class="menu-icon">
                            <i class="menu-link-icon fal fa-search-dollar"></i>
                        </span>
                        <span class="menu-text">NCRs</span>
                    </div>
                </li>
                <li class="menu-item" (click)="certificatesClick()" type="button">
                    <div class="menu-link">
                        <span class="menu-icon">
                            <i class="menu-link-icon fal fa-file-certificate"></i>
                        </span>
                        <span class="menu-text">Certificates</span>
                    </div>
                </li>
                <li class="menu-item" (click)="cparsClick()" type="button">
                    <div class="menu-link">
                        <span class="menu-icon">
                            <i class="menu-link-icon fal fa-file-alt"></i>
                        </span>
                        <span class="menu-text">CPARs</span>
                    </div>
                </li>
            </ul>
        </div>
    </li>
</ul>
<hr class="line-divider">
