import { Injectable } from '@angular/core';
import { HttpBaseService } from '../httpbase.service';
import { Observable } from 'rxjs';

const LANGUAGES: { id: number, name: string }[] = [
    { id: 9005000000, name: "English" },
    { id: 9005000001, name: "French" },
    { id: 9005000002, name: "German" },
    { id: 9005000003, name: "Spanish" }];


const SALESREP: { id: number, name: string }[] = [
    { id: 4000000004, name: "Hayley Chapman" },
    { id: 4000000036, name: "Suzette Gradon" }];

const CLIENTCLASSIFICATIONS: { id: number, name: string }[] = [
    { id: 1, name: "Critical" },
    { id: 2, name: "Non-Critical" }];

@Injectable({
    providedIn: 'root'
})

export class DataDictionaryService {
    constructor(private httpBaseService: HttpBaseService) { }


     public getLanguages(): Observable<any> {
         const apiUrl = 'DataDictionary/GetLanguages'
         return this.httpBaseService.get(apiUrl);
     }


    public getUserOrganizationUnits(userId): Observable<any> {
        const apiUrl = 'DataDictionary/GetUserOrganizationUnits/' + userId
        return this.httpBaseService.get(apiUrl);
    }

    public getSalesRep(): Observable<any> {
        const apiUrl = 'DataDictionary/GetSalesReps'
        return this.httpBaseService.get(apiUrl);
    }

    public getLanguageList(): { id: number, name: string }[] {
        return LANGUAGES;
    }

    public getSalesRepList(): { id: number, name: string }[] {
        return SALESREP;
    }

    public getClientClassifications(): { id: number, name: string }[] {
        return CLIENTCLASSIFICATIONS;
    }

}
