<dx-data-grid [dataSource]="typeList" [rowAlternationEnabled]="false" [showColumnLines]="false"
    [columnHidingEnabled]="false" [showRowLines]="true" [showBorders]="true" [remoteOperations]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)" [wordWrapEnabled]="true" [width]="100+'%'">
    <dxo-search-panel [visible]="true" [width]="100+'%'"></dxo-search-panel>
    <dxo-paging [enabled]="true" [pageSize]="10" [pageIndex]="0">
    </dxo-paging>
    <dxo-pager [showInfo]="true">
    </dxo-pager>
    <dxi-column *ngIf="!isRecordReadOnly" type="buttons" caption="" [width]="70" alignment="center">
        <dxi-button text="Edit" icon="edit" [onClick]="editType">
        </dxi-button>
        <dxi-button text="Delete" icon="trash" [onClick]="deleteType">
        </dxi-button>
    </dxi-column>
    <dxi-column caption="Standard Category" dataField="standardCategoryName"></dxi-column>
    <dxi-column caption="Added On" dataField="creationTime" cellTemplate="dateCell"></dxi-column>
    <div *dxTemplate="let cellData of 'dateCell'">
        {{cellData.value | date: 'MM/dd/yyyy hh:mm:ss a'}}
    </div>
    <dxi-column caption="Percentage" dataField="totalJustificationPercent" cellTemplate="percentCell"></dxi-column>
    <div *dxTemplate="let cellData of 'percentCell'">
        {{cellData.text}}%
    </div>
    <dxi-column caption="Additive" dataField="increase"></dxi-column>
    <dxi-column caption="Added By" dataField="createdByUser"></dxi-column>
    <dxi-column caption="Justification" dataField="explanation"></dxi-column>
    <dxi-column caption="Reason" dataField="justificationReasons">
    </dxi-column>
</dx-data-grid>
<app-justification-modal #configModal [ownerId]="ownerId" [module]="module" (modalSave)="saveType($event)"></app-justification-modal>
