<p-sidebar id="company-sidebar" [(visible)]="showSideBar" (onHide)="close()" class="company-sidebar">
    <div>
        <div class="sidebar-header-wrapper company-sidebar-header">
            <h4>
                <i class="mr-4 far fa-building"></i><span>{{ companyDetails?.companyName }} - {{
                    companyDetails?.companyId }}</span>
            </h4>
        </div>
        <div class="sidebar-body">
            <div class="row">
                <div class="form-group col-md-6">
                    <h4> Address </h4>
                    <p> {{ companyDetails?.address1 }} {{ companyDetails?.address2 }} </p>
                    <p> {{ companyDetails?.address3 }} {{ companyDetails?.address4 }} </p>
                    <p> {{ companyDetails?.townOrCity }} {{ companyDetails?.stateName }}
                        {{companyDetails?.zipOrPostcode}} </p>
                    <p> {{ companyDetails?.countryName }} </p>
                </div>
                <div class="form-group col-md-6">
                    <h4> CSR </h4>
                    <div *ngIf="companyDetails?.primaryCSR">
                        <p> <span> <i class="mr-2 fas fa-user-alt"></i> {{
                                companyDetails?.primaryCSR?.fullName || 'No Data Provided' }} </span> </p>
                        <p> <span> <i
                                    class="mr-2 far fa-id-badge"></i> {{
                                companyDetails?.primaryCSR?.combinedAddressLineWithCountyStateCountry || 'No Data Provided' }} </span> </p>
                        <p> <span> <i class="mr-2 fas fa-phone-alt"></i> {{
                                companyDetails?.primaryCSR?.phone || 'No Data Provided' }} </span> </p>
                        <p> <span> <i class="mr-2 fas fa-mobile-alt"></i>{{
                                companyDetails?.primaryCSR?.cell || 'No Data Provided' }} </span> </p>
                        <p> <span> <i class="mr-2 far fa-envelope"></i>
                                <a href="mailto:companyDetails?.primaryCSR?.email" class="link-text">{{ companyDetails?.primaryCSR?.email || 'No Data Provided' }} </a>
                            </span> </p>
                    </div>

                    <div *ngIf="!companyDetails?.primaryCSR" class="no-data"><span>No Data Provided</span></div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <h4> Main Contact </h4>
                    <div *ngIf="companyDetails?.mainContact">
                        <p> <span> <i class="mr-2 fas fa-user-alt"></i>{{
                                companyDetails?.mainContact?.fullName  || 'No Data Provided' }} </span> </p>
                        <p> <span> <i
                                    class="mr-2 far fa-id-badge"></i> {{
                                companyDetails?.mainContact?.combinedAddressLineWithCountyStateCountry  || 'No Data Provided' }} </span> </p>
                        <p> <span> <i class="mr-2 fas fa-phone-alt"></i>{{
                                companyDetails?.mainContact?.phone  || 'No Data Provided' }} </span> </p>
                        <p> <span> <i class="mr-2 fas fa-mobile-alt"></i> {{
                                companyDetails?.mainContact?.cell || 'No Data Provided' }} </span> </p>
                        <p> <span> <i class="mr-2 far fa-envelope"></i>
                                <a href="mailto:companyDetails?.mainContact?.email" class="link-text">{{ companyDetails?.mainContact?.email  || 'No Data Provided'}}</a>
                            </span> </p>
                    </div>

                    <div *ngIf="!companyDetails?.mainContact" class="no-data"><span>No Data Provided</span></div>
                </div>
                <div class="form-group col-md-6">
                    <h4> Program Manager </h4>
                    <div *ngIf="companyDetails?.primaryPM">
                        <p> <span> <i class="mr-2 fas fa-user-alt"></i>{{
                                companyDetails?.primaryPM?.fullName || 'No Data Provided' }}</span> </p>
                        <p> <span> <i
                                    class="mr-2 far fa-id-badge"></i> {{
                                companyDetails?.primaryPM?.combinedAddressLineWithCountyStateCountry || 'No Data Provided' }} </span> </p>
                        <p> <span> <i class="mr-2 fas fa-phone-alt"></i>{{
                                companyDetails?.primaryPM?.phone || 'No Data Provided' }}</span> </p>
                        <p> <span> <i class="mr-2 fas fa-mobile-alt"></i> {{
                                companyDetails?.primaryPM?.cell || 'No Data Provided' }} </span> </p>
                        <p> <span> <i class="mr-2 far fa-envelope"></i>
                                <a href="mailto:companyDetails?.primaryPM?.email" class="link-text">{{ companyDetails?.primaryPM?.email || 'No Data Provided' }}</a>
                            </span> </p>
                    </div>

                    <div *ngIf="!companyDetails?.primaryPM" class="no-data"><span>No Data Provided</span></div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <h4> Billing Contact </h4>
                    <div *ngIf="companyDetails?.billingContact">
                        <p> <span> <i
                                    class="mr-2 fas fa-user-alt"></i>{{
                                companyDetails?.billingContact?.fullName  || 'No Data Provided' }}</span> </p>
                        <p> <span> <i
                                    class="mr-2 far fa-id-badge"></i> {{
                                companyDetails?.billingContact?.combinedAddressLineWithCountyStateCountry || 'No Data Provided'  }} </span>
                        </p>
                        <p> <span> <i class="mr-2 fas fa-phone-alt"></i>{{
                                companyDetails?.billingContact?.phone  || 'No Data Provided' }}</span> </p>
                        <p> <span> <i class="mr-2 fas fa-mobile-alt"></i>
                                {{
                                companyDetails?.billingContact?.cell  || 'No Data Provided' }} </span> </p>
                        <p> <span> <i class="mr-2 far fa-envelope"></i>
                                <a href="mailto:companyDetails?.billingContact?.email" class="link-text">{{ companyDetails?.billingContact?.email  || 'No Data Provided' }}</a>
                            </span> </p>
                    </div>

                    <div *ngIf="!companyDetails?.billingContact" class="no-data"><span>No Data Provided</span></div>
                </div>
                <div class="form-group col-md-6">
                    <h4> Auditors </h4>
                    <div *ngFor="let item of companyDetails?.auditorList">
                        <p> <span> <i class="mr-2 far fa-id-badge"></i>
                                <!-- <a class="link-text">{{item.fullName}} </a>  -->
                                {{item.fullName}} </span> </p>
                    </div>
                    <div *ngIf="!(companyDetails?.auditorList?.length > 0)" class="no-data"><span>No Data Provided</span></div>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <button class="btn secondary-button" (click)="close()"> <span>Close</span>
            </button>
        </div>
    </div>
</p-sidebar>
