<span class="p-fluid"> 
    <p-multiSelect 
        [options]="organizationCodes" 
        [(ngModel)]="selectedOrganizationCodes" 
        defaultLabel="Select a Code" 
        optionLabel="ouName" 
        (onChange)="onOrganizationChange($event)"
        selectionLimit = "1"
	    display="chip"></p-multiSelect>
</span>
