import { Component, Injector, Input, OnInit } from '@angular/core';
import { OutboundMessageService } from '@app/shared/services/outbound-messages/outbound-messages.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-outbound-messages',
  templateUrl: './outbound-messages.component.html',
  styleUrls: ['./outbound-messages.component.css']
})
export class OutboundMessagesComponent implements OnInit {

  @Input() tag;
  messageList: any[] = [];
  messageDetail: any = {};
  showMessageDetail: boolean = false;
  spinnerService: NgxSpinnerService;
  receipentFilter: string = '';
  fromEmail: string = ''
  subject: string = '';
  status: string = '';
  toDate: any = null;
  fromDate: any = null;
  showFilter: boolean = false;
  constructor(
    public apiService: OutboundMessageService,
    injector: Injector
  ) {
    this.spinnerService = injector.get(NgxSpinnerService);
  }

  ngOnInit() {
    this.apiService.GetOutboundMessages().subscribe((res) => {
      this.messageList = res.Messages;
    })
  }

  toggleFilter() {
    this.showFilter = !this.showFilter
  }

  onMessageClick(message) {
    this.spinnerService.show();
    this.apiService.GetOutboundMessagesDetail(message.MessageID).subscribe(res => {
      this.spinnerService.hide();
      this.showMessageDetail = true;
      this.messageDetail = res;
    })
  }

  getFirstCharacter(email: string) {
    return email[0].toUpperCase()
  }

  formatDate(date) {
    return moment(date).format('MMM DD hh:mm')

  }

  search() {
    const param = {
      recipient: this.receipentFilter,
      fromemail: this.fromEmail,
      subject: this.subject,
      status: this.status,
      todate: this.toDate,
      fromdate: this.fromDate
    };
    let params = new URLSearchParams();
    for (let key in param) {
      if (param[key] != null && param[key].match(/^ *$/) === null) {
        params.set(key, param[key]);
      }
    };
    const queryParams = params.toString();
    this.spinnerService.show();
    this.apiService.GetOutboundMessagesWithParams(queryParams).subscribe(res => {
      this.spinnerService.hide();
      this.messageList = res.Messages;
    }, (error: any) => {
      this.spinnerService.hide();
    });
  }

  reset() {
    this.receipentFilter = ''
    this.fromEmail = ''
    this.subject = ''
    this.status = ''
    this.toDate = null
    this.fromDate = null
    this.spinnerService.show();
    this.apiService.GetOutboundMessages().subscribe((res) => {
      this.spinnerService.hide();
      this.messageList = res.Messages;
    })
  }

}
