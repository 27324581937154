<div appBsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal"
    aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="user-validator">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>{{modalTitle}}</span>
                    </h4>
                    <button type="button" class="close" (click)="close()">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div *ngIf="activeSection == 0">
                        A main address already exists for this client. Only one main address can exists within a client
                        group. You can make this location a main address
                        and it will replace the existing with the new client record and make it the main address. Please
                        be certain that this is the change you want to make.
                        <div class="mt-10">
                            Do you want to proceed?
                        </div>
                    </div>
                    <div *ngIf="activeSection == 5">
                        You are about to change the main address to a different one. You can make this location an address type other than main address type. Please
                        be certain that this is the change you want to make.
                        <div class="mt-10">
                            Do you want to proceed?
                        </div>
                    </div>

                    <div *ngIf="activeSection == 1">
                        Since you are making the Client <span class="bold-700">{{companyNameOfNewMainLocation}}</span> the main address, you must select an address type for
                        existing client <span class="bold-700">{{companyNameOfExistingMainLocation}}</span> that you are replacing. Please select below.
                        <ng-select [selectOnTab]="true" class="mt-5" name="addressType" [(ngModel)]="selectedAddressType">
                            <ng-container *ngFor="let c of addressTypes">
                                <ng-option *ngIf="c.isDeleted === false && c.id != mainAddressTypeId" [value]="c">
                                    {{c.name}}</ng-option>
                            </ng-container>
                        </ng-select>
                    </div>
                    <div *ngIf="activeSection == 6">
                        Since you are making another location the main address, you must select an address type for
                        existing client <span class="bold-700">{{companyNameOfExistingMainLocation}}</span> that you are replacing. Please select below.
                        <ng-select [selectOnTab]="true" class="mt-5" name="addressType" [(ngModel)]="selectedAddressType">
                            <ng-container *ngFor="let c of addressTypes">
                                <ng-option *ngIf="c.isDeleted === false && c.id != mainAddressTypeId" [value]="c">
                                    {{c.name}}</ng-option>
                            </ng-container>
                        </ng-select>
                    </div>
                    <div *ngIf="activeSection == 2">
                        The existing Client <span class="bold-700">{{companyNameOfExistingMainLocation}}</span> is being replaced in the hiearchy, please select the correct parent entity
                        of the Client <span class="bold-700">{{companyNameOfExistingMainLocation}}</span> being replaced.
                        <div class="radio-btn-wrapper">
                            <label class="form-group text-nowrap mr-5 font-weight-bold">Display:</label>
                            <div class="form-group radio-inline mt-5">
                                <label class="radio radio-success mb-0">
                                    <input type="radio" [value]="0" name="filterLocation"
                                        [(ngModel)]="selectedLocationOption" />
                                    <span></span>
                                    Child of new main location
                                </label>
                                <label class="radio radio-success mb-0">
                                    <input type="radio" [value]="1" name="filterLocation"
                                        [(ngModel)]="selectedLocationOption" />
                                    <span></span>
                                    Child of existing location
                                </label>
                            </div>
                        </div>
                        <ng-select [selectOnTab]="true" class="mt-5" name="locationList" *ngIf="selectedLocationOption==1"
                            [(ngModel)]="selectedCompanyLocation">
                            <ng-container *ngFor="let c of existingLocationList">
                                <ng-option [value]="c">{{c.locationName}}</ng-option>
                            </ng-container>
                        </ng-select>

                    </div>
                    <div *ngIf="activeSection == 7">
                        The existing Client <span class="bold-700">{{companyNameOfExistingMainLocation}}</span> is being replaced in the hiearchy, please select the new main location.
                        
                        <ng-select [selectOnTab]="true" class="mt-5" name="locationList"
                            [(ngModel)]="selectedCompanyLocation">
                            <ng-container *ngFor="let c of existingLocationList">
                                <ng-option [value]="c">{{c.locationName}}</ng-option>
                            </ng-container>
                        </ng-select>

                    </div>
                </div>
                <div class="modal-footer">

                    <button *ngIf="activeSection == 2 || activeSection == 7" 
                    type="button" class="btn btn-primary button-add add-btn ml-auto"
                        (click)="saveAddressDetails()">Save </button>
                    <button *ngIf="activeSection != 2 && activeSection != 7" 
                    [disabled]="activeSection == 1 && selectedAddressType == null || activeSection == 6 && selectedAddressType == null "
                    type="button" class="btn btn-primary button-add add-btn ml-auto"
                        (click)="proceedToChangeMainAddress()">Proceed </button>
                    <button type="button" class="btn btn-light-danger font-weight-bold" (click)="close()">{{"Cancel" |
                        localize}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
