import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBaseComponent } from '../widget-component-base';
import { WidgetService } from '../widget.service';
import { DateTime } from 'luxon';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import ODataStore from 'devextreme/data/odata/store';
import { environment } from 'environments/environment';
import { LoadOptions } from 'devextreme/data/load_options';
import { RevenueSummary } from './revenue-summary.model';

@Component({
  selector: 'app-widget-finance-revenue-summary',
  templateUrl: './widget-finance-revenue-summary.component.html',
  styleUrls: ['./widget-finance-revenue-summary.component.css']
})
export class WidgetFinanceRevenueSummaryComponent extends WidgetComponentBaseComponent implements OnInit, OnDestroy {

    store: ODataStore;
    revenueSummary: RevenueSummary[] = [];

    selectedDateRange: DateTime[] = [this._dateTimeService.getStartOfDayMinusDays(7), this._dateTimeService.getEndOfDay()];

    constructor(injector: Injector,
    private _widgetService: WidgetService,
    private _dateTimeService: DateTimeService) {
    super(injector);
  }

  ngOnInit(): void {
    this.subDateRangeFilter();
    this.runDelayed(()=>{
        this.getFinanceRevenueSummary();
    });
  }

  getFinanceRevenueSummary = () => {

    this.store = new ODataStore({
            url: environment.odataBaseUrl + 'GetRevenueSummary',
            version: 4,
            withCredentials: true,
            key: 'Id',
            keyType: 'Int64',

            beforeSend: (e) => {
                e.method = "get",
                    e.headers = {
                        "x-cdata-authtoken": environment.odataKey
                    }//,
                // e.params = {
                //     "UserId": abp.session.userId
                // }
            }
            // Other ODataStore properties go here
        });
        let options: LoadOptions = {
            //filter: [ "CompanyName", "=", "ABC Company" ]
            // Data processing settings are specified here
            //sort: ["LastName", "FirstName"]
        };
        let returnData: any;
        this.store.load(options)
            .then(
                (data) => {
                    this.revenueSummary = data;
                    console.log(this.revenueSummary);
                },
                (error) => {
                    console.log(error);
                }
            );
  }

  onDateRangeFilterChange = (dateRange) => {
    if (!dateRange || dateRange.length !== 2 || (this.selectedDateRange[0] === dateRange[0] && this.selectedDateRange[1] === dateRange[1])) {
      return;
    }

    this.selectedDateRange[0] = dateRange[0];
    this.selectedDateRange[1] = dateRange[1];
    //this.runDelayed(this.getRevenueSummary());
  }

  subDateRangeFilter() {
    abp.event.on('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  unSubDateRangeFilter() {
    abp.event.off('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  ngOnDestroy(): void {
    this.unSubDateRangeFilter();
  }
}
