import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-temporary-header',
  templateUrl: './temporary-header.component.html',
  styleUrls: ['./temporary-header.component.css']
})
export class TemporaryHeaderComponent implements OnInit {
  isQuickAccessOpen = true;

  constructor(
    private router: Router,
  ) { }

  toggleQuickAccess() {
    this.isQuickAccessOpen = !this.isQuickAccessOpen;
  }

  ngOnInit(): void {
  }

  companyClick(){
    this.router.navigate(['app/company/list']);
  }
  clientClick(){
    this.router.navigate(['app/client/client-list']);
  }
  peopleClick(){
    this.router.navigate([`app/person/list`]);
  }
  contactClick(){
    this.router.navigate([`/app/contact/list`]);
  }
  auditorClick(){
    this.router.navigate([`app/auditor/list`]);
  }
  dataDictionariesClick(){
    this.router.navigate([`app/config/config`]);    
  }
  quoteClick(){
    this.router.navigate([`app/quote/list`]);
  }
  contractReviewClick(){
    this.router.navigate([`app/quote/contractreview/list`]);
  }
  auditClick(){
    this.router.navigate([`app/schedule/list`]);
  }
  ncrClick(){
    this.router.navigate(["app/ncr/list"]);
  }
  certificatesClick(){
    this.router.navigate(["app/certificate/list"]);
  }
  cparsClick(){
    this.router.navigate(["app/cpars/list"]);
  }

}
