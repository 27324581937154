import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBaseService } from '../httpbase.service';

@Injectable({
  providedIn: 'root'
})
export class StandardCategoryService {

  constructor(private httpBaseService: HttpBaseService) { }

  public getStandardCategories(userId): Observable<any> {
    const apiUrl = 'StandardCategory/GetStandardCategories/' + userId
    return this.httpBaseService.get(apiUrl);
  }

  public getStandardCategoriesAssociatedWithClientCertificate(id, userId): Observable<any> {
    const apiUrl = 'StandardCategory/GetStandardCategoriesAssociatedWithClientCertificate/' + id + '/' + userId
    return this.httpBaseService.get(apiUrl);
  }

  public getStandardCategoriesAssociatedWithQuote(id, userId): Observable<any> {
    const apiUrl = 'StandardCategory/GetStandardCategoriesAssociatedWithQuote/' + id + '/' + userId
    return this.httpBaseService.get(apiUrl);
  }

  public getStandardCategoriesAssociatedWithContractReview(id, userId): Observable<any> {
    const apiUrl = 'StandardCategory/GetStandardCategoriesAssociatedWithContractReview/' + id + '/' + userId
    return this.httpBaseService.get(apiUrl);
  } 

  public getStandardCategoriesToIntegrateByCategorIds(payload): Observable<any> {
    const apiUrl = 'StandardCategory/GetStandardCategoriesToIntegrateByCategorIds';
    return this.httpBaseService.post(apiUrl, payload);
  }
}
