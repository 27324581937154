import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBaseService } from '../httpbase.service';

@Injectable({
  providedIn: 'root'
})
export class CparsService {

  constructor(private httpBaseService: HttpBaseService) { }

  public getClientCPARsUsingServerSidePagination(payload): Observable<any> {
    const apiUrl = 'ClientCPAR/GetClientCPARsUsingServerSidePagination';
    return this.httpBaseService.post(apiUrl, payload)
  }

  getClientCPARs(userId): Observable<any> {
    const apiUrl = 'ClientCPAR/GetClientCPARs/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
  getClientCPARsByClientId(clientId, userId): Observable<any> {
    const apiUrl = 'ClientCPAR/GetClientCPARsByClientId/' + clientId + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
  getClientCPARById(id, userId): Observable<any> {
    const apiUrl = 'ClientCPAR/GetClientCPARById/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
  getClientCPARStatusHistory(id, userId): Observable<any> {
    const apiUrl = 'ClientCPAR/GetClientCPARStatusHistory/' + id;
    return this.httpBaseService.get(apiUrl);
  }

  createClientCPAR(payload): Observable<any> {
    const apiUrl = 'ClientCPAR/CreateClientCPAR';
    return this.httpBaseService.post(apiUrl, payload);
  }

  updateClientCPAR(payload): Observable<any> {
    return this.httpBaseService.post('ClientCPAR/UpdateClientCPAR', payload);
  }

  updateClientCPARStatus(cparId, statusId, userId): Observable<any> {
    return this.httpBaseService.get('ClientCPAR/UpdateClientCPARStatus/' + cparId + '/' + statusId + '/' + userId);
  }

  deleteClientCPAR(payload): Observable<any> {
    return this.httpBaseService.post('ClientCPAR/DeleteClientCPAR', payload);
  }

  getClientCPARLocationsByCPARId(id, userId): Observable<any> {
    const apiUrl = 'ClientCPARLocation/GetClientCPARLocationsByCPARId/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
}
