import { Injectable } from '@angular/core';
import { HttpBaseService } from '../httpbase.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrencyUnitsService {

  constructor(private httpBaseService: HttpBaseService) { }

  public getCurrencyTypes(userId): Observable<any> {
    const apiUrl = 'CurrencyType/GetCurrencyTypes/'+ userId
    return this.httpBaseService.get(apiUrl);
  }

}
