import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "filterVisibility"
})
export class FilterVisibilityPipe implements PipeTransform {
    transform(array: any[], value: boolean): any[] {
        return array.filter(item=>item.isVisible==value);
    }
}
