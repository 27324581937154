import { Component, OnInit, ViewChild, Output, EventEmitter, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CompanyPopupService } from './company-popup.service';
import { CompanyService } from '@app/company/shared/services/company.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AddressService } from '@app/shared/services/address/address.service';

@Component({
    selector: 'app-company-popup',
    templateUrl: './company-popup.component.html',
    styleUrls: ['./company-popup.component.css']
})
export class CompanyPopupComponent extends AppComponentBase implements OnInit {
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    companyDetails: any;
    showSideBar = false;
    constructor(
        private companyPopupService: CompanyPopupService,
        private companyService: CompanyService,
        private addressService: AddressService,
        injector: Injector) {
        super(injector)
    }

    ngOnInit(): void {
        this.companyPopupService.showCompanyPopup$.subscribe((id) => {
            // this.companyService.getCompanyDetails(id, this.appSession.user.id).subscribe((x) => {
            //     this.companyDetails = x;
            // });

            // this.addressService.GetAddressesByCompanyId(id, this.appSession.user.id).subscribe((x) => {
            //     this.companyDetails.address = x;
            // });

            this.getCompanyDetails(id);

            // this.companyDetails = {
            //     id: 1000524,
            //     name: 'NQS',
            //     businessTypeId: 'NTS UK',
            //     companyTypeId: 'NTS Company',
            //     address: 'Location X Northern Ring Road 11564',
            //     mainPhone: '800-649-5289',
            //     website: 'https://www.nqa.com/en-us'
            // }
        })

    }

    getCompanyDetails(id){
        this.spinnerService.show();
        this.companyService.getCompanyDetailsByCompanyId(id,this.appSession.user.id ).subscribe(
            (data: any) => { 
                this.companyDetails = data; 
                this.spinnerService.hide();
                this.show();
                
             }, (error: any) => { 
                abp.log.error(error);
                abp.notify.error("", "Failed to load company details", { showCloseButton: true, timer: 10000 });
                this.spinnerService.hide();
            }
        )
    }

    show(): void {
        this.showSideBar = true;
    }

    close() {
        this.showSideBar = false;
        this.companyDetails = {}
    }

}
