import { Injectable } from '@angular/core';
import { HttpBaseService } from '../httpbase.service';
import { Observable } from 'rxjs';
import { LegalStatus, ReorderLegalStatus } from '@app/shared/models/config/legal-status.model';

@Injectable({
  providedIn: 'root'
})
export class LegalStatusService {

  constructor(private httpBaseService: HttpBaseService) { }

  public getLegalTypeList(userId): Observable<any> {
    const apiUrl = 'LegalStatus/GetLegalStatuses/' + userId
    return this.httpBaseService.get(apiUrl);
  }
  createLegalStatus(legalStatus: LegalStatus): Observable<any> {
    return this.httpBaseService.post('LegalStatus/CreateLegalStatus', legalStatus);
  }
  updateLegalStatus(legalStatus: LegalStatus): Observable<any> {
    return this.httpBaseService.post('LegalStatus/UpdateLegalStatus', legalStatus);
  }
  deleteLegalStatus(legalStatus: LegalStatus): Observable<any> {
    return this.httpBaseService.post('LegalStatus/DeleteLegalStatus', legalStatus);
  }
  reorderLegalStatus(legalStatus: ReorderLegalStatus): Observable<any> {
    return this.httpBaseService.post('LegalStatus/ReorderLegalStatuses', legalStatus);
  }

}
