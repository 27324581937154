<dx-data-grid [dataSource]="contractReviewList" [rowAlternationEnabled]="false" [showColumnLines]="false"
    [columnHidingEnabled]="false" [showRowLines]="true" [showBorders]="true" [remoteOperations]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)" [wordWrapEnabled]="true" [width]="100+'%'">
    <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
    <dxo-search-panel [visible]="true" [text]="searchString" [width]="100+'%'"></dxo-search-panel>
    <!-- <dxi-column type="buttons" caption="" [width]="50">
                <dxi-button text="Edit" icon="edit" [onClick]="editContractReview">
                </dxi-button>
            </dxi-column> -->
    <!-- <dxi-column type="buttons" caption="" [width]="50">
                <dxi-button text="Delete" icon="trash" [onClick]="deleteContractReview">
                </dxi-button>
            </dxi-column> -->
    <dxi-column caption="Contract Review ID" dataField="id" [width]="120" alignment="left" cellTemplate="viewContractReview"></dxi-column>
    <div *dxTemplate="let cell of 'viewContractReview'">
        <a (click)="viewContractReview(cell.data.id)" class="a-link" target="_blank">{{ cell.text }}</a>
    </div>
    <dxi-column caption="Company Name" [width]="220" dataField="companyName" alignment="left"
        cellTemplate="openClientPopUp">

        <div *dxTemplate="let cell of 'openClientPopUp'">
            <div *ngIf="cell.data.companyId > 0">
                <a (click)="openClientPopUp(cell.data.companyId)">{{cell.text}}</a>
            </div>
            <div *ngIf="cell.data.companyId == 0">
                {{cell.text}}
            </div>
        </div>
    </dxi-column>
    <dxi-column caption="Standard Groups(s)" dataField="standardGroups"
        alignment="left"></dxi-column>
    <dxi-column caption="Site(s)" [width]="400" dataField="sites"
        alignment="left"></dxi-column>
    <dxi-column caption="Status" [width]="120" dataField="contractReviewStatusName" alignment="left"></dxi-column>
    <dxi-column caption="Created" [width]="120" dataField="creationTime" cellTemplate="dateCell" alignment="left">
    </dxi-column>
    <div *dxTemplate="let cellData of 'dateCell'">
        {{cellData.value | date: 'MM/dd/yyyy'}}
    </div>
</dx-data-grid>
