import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBaseService } from '../httpbase.service';
import { STANDARDS } from '@app/shared/data/standard-data';

@Injectable({
  providedIn: 'root'
})
export class AuditStandardService {

  constructor(private httpBaseService: HttpBaseService) { }

  public getAuditStandards(userId): Observable<any> {
    const apiUrl = 'ClientStandard/GetClientStandards/'+ userId
    return this.httpBaseService.get(apiUrl);
  }

//   public getStandards(): { id: number, name: string }[] {
//      return STANDARDS;
//   }
}
