import { Injectable } from '@angular/core';
import { HttpBaseService } from '@app/shared/services/httpbase.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NcrService {  

  constructor(private httpBaseService: HttpBaseService) { }

  public getNcrs(userId): Observable<any> {
    const apiUrl = 'Ncr/GetNcrs/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
  public getNcrList(payload): Observable<any> {
    return this.httpBaseService.post('Ncr/GetNcrListUsingServerSidePagination', payload)
  }

  public getNcrConsolidatedById(id, userId): Observable<any> {
    const apiUrl = 'Ncr/GetNcrConsolidatedById/' + id + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public getNcrTypes(userId): Observable<any> {
    const apiUrl = 'NcrType/GetNcrTypes/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
  public createNcrConsolidated(payload): Observable<any> {
    return this.httpBaseService.post('Ncr/CreateNcrConsolidated', payload)
  }

  public updateNcr(payload): Observable<any> {
    return this.httpBaseService.post('Ncr/UpdateNcr', payload)
  }

  public createNcrContainment(payload): Observable<any> {
    return this.httpBaseService.post('NcrContainment/CreateNcrContainment', payload)
  }  
  public createNcrContainmentObjectiveEvidence(payload): Observable<any> {
    return this.httpBaseService.post('NcrContainmentObjectiveEvidence/CreateNcrContainmentObjectiveEvidence', payload)
  }
  public createNcrCorrection(payload): Observable<any> {
    return this.httpBaseService.post('NcrCorrection/CreateNcrCorrection', payload)
  }
  public createCorrectionObjectiveEvidenceConfig(payload): Observable<any> {
    return this.httpBaseService.post('NcrCorrectionObjectiveEvidence/CreateNcrCorrectionObjectiveEvidence', payload)
  }
  public createRootCause(payload): Observable<any> {
    return this.httpBaseService.post('NcrRootCause/CreateNcrRootCause', payload)
  }  
  public createNcrCorrectiveAction(payload): Observable<any> {
    return this.httpBaseService.post('NcrCorrectiveAction/CreateNcrCorrectiveAction', payload)
  }  
  public createNcrCorrectiveActionObjectiveEvidence(payload): Observable<any> {
    return this.httpBaseService.post('NcrCorrectiveActionObjectiveEvidence/CreateNcrCorrectiveActionObjectiveEvidence', payload)
  }

  public createNcrComment(payload): Observable<any> {
    return this.httpBaseService.post('NcrComment/CreateNcrComment', payload)
  }

  public updateNcrContainment(payload): Observable<any> {
    return this.httpBaseService.post('NcrContainment/UpdateNcrContainment', payload)
  }  
  public updateNcrContainmentObjectiveEvidence(payload): Observable<any> {
    return this.httpBaseService.post('NcrContainmentObjectiveEvidence/UpdateNcrContainmentObjectiveEvidence', payload)
  }
  public updateNcrCorrection(payload): Observable<any> {
    return this.httpBaseService.post('NcrCorrection/UpdateNcrCorrection', payload)
  }
  public updateCorrectionObjectiveEvidenceConfig(payload): Observable<any> {
    return this.httpBaseService.post('NcrCorrectionObjectiveEvidence/UpdateNcrCorrectionObjectiveEvidence', payload)
  }
  public updateRootCause(payload): Observable<any> {
    return this.httpBaseService.post('NcrRootCause/UpdateNcrRootCause', payload)
  }
  public updateCorrectiveAction(payload): Observable<any> {
    return this.httpBaseService.post('NcrCorrectiveAction/UpdateNcrCorrectiveAction', payload)
  }
  public updateCorrectiveActionObjectiveEvidence(payload): Observable<any> {
    return this.httpBaseService.post('NcrCorrectiveActionObjectiveEvidence/UpdateNcrCorrectiveActionObjectiveEvidence', payload)
  }
  
  public deleteNcrContainment(payload): Observable<any> {
    return this.httpBaseService.post('NcrContainment/DeleteNcrContainment', payload)
  }  
  public deleteNcrContainmentObjectiveEvidence(payload): Observable<any> {
    return this.httpBaseService.post('NcrContainmentObjectiveEvidence/DeleteNcrContainmentObjectiveEvidence', payload)
  }
  public deleteNcrCorrection(payload): Observable<any> {
    return this.httpBaseService.post('NcrCorrection/DeleteNcrCorrection', payload)
  }
  public deleteCorrectionObjectiveEvidenceConfig(payload): Observable<any> {
    return this.httpBaseService.post('NcrCorrectionObjectiveEvidence/DeleteNcrCorrectionObjectiveEvidence', payload)
  }
  public deleteRootCause(payload): Observable<any> {
    return this.httpBaseService.post('NcrRootCause/DeleteNcrRootCause', payload)
  }
  public deleteCorrectiveAction(payload): Observable<any> {
    return this.httpBaseService.post('NcrCorrectiveAction/DeleteNcrCorrectiveAction', payload)
  }
  public deleteCorrectiveActionObjectiveEvidence(payload): Observable<any> {
    return this.httpBaseService.post('NcrCorrectiveActionObjectiveEvidence/DeleteNcrCorrectiveActionObjectiveEvidence', payload)
  }

  public getNCRDaysDues(userId): Observable<any> {
    const apiUrl = 'NCRDaysDue/GetNCRDaysDues/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
}
