import { Injectable } from '@angular/core';
import { HttpBaseService } from '../httpbase.service';
import { Observable } from 'rxjs';
import { ReorderSponsor, Sponsor } from '@app/shared/models/config/sponsor.model';

@Injectable({
  providedIn: 'root'
})
export class SponsorService {

  constructor(private httpBaseService: HttpBaseService) { }

  public getSponsorList(userId): Observable<any> {
    const apiUrl = 'Sponsor/GetSponsors/'+ userId
    return this.httpBaseService.get(apiUrl);
  }
  createSponsor(sponsor: Sponsor): Observable<any> {
    return this.httpBaseService.post('Sponsor/CreateSponsor', sponsor);
  }
  updateSponsor(sponsor: Sponsor): Observable<any> {
    return this.httpBaseService.post('Sponsor/UpdateSponsor', sponsor);
  }
  deleteSponsor(sponsor: Sponsor): Observable<any> {
    return this.httpBaseService.post('Sponsor/DeleteSponsor', sponsor);
  }
  reorderSponsor(sponsor: ReorderSponsor): Observable<any> {
    return this.httpBaseService.post('Sponsor/ReorderSponsors', sponsor);
  }

}
