<div class="checklist-grid-container">
    <dx-data-grid class="dx-grid-max-height" [dataSource]="checklists" [rowAlternationEnabled]="false"
        [showColumnLines]="false" [columnHidingEnabled]="true" [showRowLines]="true" [showBorders]="true"
        [wordWrapEnabled]="true">

        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <!-- <dxo-search-panel [visible]="true" [width]="100+'%'" [text]="searchString"></dxo-search-panel> -->
        <dxi-column caption="" [width]="40" cellTemplate="viewIcon">
        </dxi-column>
        <div *dxTemplate="let cell of 'viewIcon'">
            <div style="text-align: center; width: 100%;" (click)="viewChecklist(cell.data.id)">
                <i class="fa fa-search" style="color: var(--primary-color);" title="View"></i>
            </div>
        </div>
        <dxi-column caption="Type" dataField="auditChecklistChecklistTypeName" dataType="string" cellTemplate="viewCellTemplate">
        </dxi-column>
        <div *dxTemplate="let cell of 'viewCellTemplate'">
            <a (click)="viewChecklist(cell.data.id)" class="a-link" target="_blank">{{ cell.text }}</a>
        </div>
        <dxi-column caption="Date" dataField="date" dataType="string"></dxi-column>
        <!-- <dxo-paging [pageSize]="200">
        </dxo-paging>
        <dxo-pager [showInfo]="true">
        </dxo-pager> -->
    </dx-data-grid>
</div>
