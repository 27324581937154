import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {

    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'fal fa-chart-line', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'fal fa-chart-line', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('Sales', '', 'fal fa-handshake', '', [], [
                new AppMenuItem('Dashboard', '', 'fal fa-chart-line', '/app/sales/sales-dashboard/dashboard'),
                new AppMenuItem('Prospects', '', 'fal fa-search-dollar', '/app/prospect/list'),
                new AppMenuItem('Backlog', '', 'fal fa-server', '/app/backlog/list'),
                new AppMenuItem('Salesforce', '', 'fab fa-salesforce', '/app/salesforce/list'),
                new AppMenuItem('Quotes', '', 'fal fa-file-invoice-dollar', '/app/quote/list'),
                new AppMenuItem('Contracts', '', 'fal fa-file-signature', '/app/contract/list'),
                new AppMenuItem('On-Boarding', '', 'fal fa-address-card', '/app/onboard/list'),
                new AppMenuItem('Documents', '', 'fal fa-file-search', '/app/document/list'),
                new AppMenuItem('Reports', '', 'fal fa-file-invoice', '/app/report/list')
            ]),
            new AppMenuItem('Operations', '', 'fal fa-cogs', '', [], [
                new AppMenuItem('Companies', '', 'fal fa-building', '/app/company/list'),
                new AppMenuItem('Clients', '', 'fal fa-users-crown', '/app/client/list'),
                // new AppMenuItem('Clients', '', 'fal fa-users-crown', '', [], [
                //     new AppMenuItem('List', '', 'fal fa-list-alt', '/app/client/list'),
                //     new AppMenuItem('CSR Multisite', '', 'flaticon-business', '/app/main/dashboard'),
                //     new AppMenuItem('Ops Multisite', '', 'flaticon-business', '/app/main/dashboard'),
                // ]),
                new AppMenuItem('People', '', 'fal fa-users', '/app/person/list'),
                new AppMenuItem('Auditors', '', 'fal fa-users-class', '/app/auditor/list'),
                // new AppMenuItem('Auditors', '', 'fal fa-users-class', '', [], [
                //     new AppMenuItem('List', '', 'fal fa-list-alt', '/app/auditor/list'),
                //     new AppMenuItem('Utilization Management', '', 'fal fa-tasks-alt', '/app/main/dashboard'),
                //     new AppMenuItem('Allocation Management', '', 'fal fa-tasks', '/app/main/dashboard'),
                //     new AppMenuItem('Admin', '', 'fal fa-tools', '/app/main/dashboard')
                // ]),
                new AppMenuItem('Contacts', '', 'fal fa-id-card-alt', '/app/contact/list'),
                new AppMenuItem('Scheduling', '', 'fal fa-calendar-alt', '/app/schedule/list'),
                new AppMenuItem('NCRs', '', 'fal fa-search-dollar', '/app/ncr/list'),
                new AppMenuItem('Certificates', '', 'fal fa-file-certificate', '/app/certificate/list'),
                new AppMenuItem('Documents', '', 'fal fa-file-alt', '/app/document/list'),
                new AppMenuItem('FileManager', '', 'fal fa-file-alt', '/app/file-manager'),
                new AppMenuItem('Reports', '', 'fal fa-file-invoice', '/app/report/list')
            ]),
            new AppMenuItem('Finance', '', 'fal fa-piggy-bank', '', [], [
                new AppMenuItem('Dashboard', '', 'fal fa-chart-line', '/app/finance/finance-dashboard/dashboard'),
                new AppMenuItem('Time & Expense', '', 'fal fa-user-clock', '/app/time-expense/list'),
                new AppMenuItem('Payroll', '', 'fal fa-money-bill-alt', '/app/payroll/list'),
                new AppMenuItem('Billing', '', 'fal fa-file-invoice-dollar', '/app/billing/list'),
                new AppMenuItem('FO Integration', '', 'fal fa-laptop-code', '/app/fo/list'),
                new AppMenuItem('Documents', '', 'fal fa-file', '/app/document/list'),
                new AppMenuItem('Reports', '', 'fal fa-file-invoice', '/app/report/list')
            ]),
            new AppMenuItem('Quality', '', 'fal fa-certificate', '', [], [
                new AppMenuItem('Dashboard', '', 'fal fa-chart-line', '/app/quality/quality-dashboard/dashboard'),
                new AppMenuItem('Quality Manual', '', 'fal fa-book-spells', '/app/quality-manual/list'),
                new AppMenuItem('CAPA', '', 'fal fa-pencil-ruler', '/app/capa/list'),
                new AppMenuItem('Audits', '', 'fal fa-search-location', '/app/audit/list'),
                new AppMenuItem('Documents', '', 'fal fa-file', '/app/document/list'),
                new AppMenuItem('Forms & Procedures', '', 'fal fa-file-alt', '/app/form-procedure/list'),
                // new AppMenuItem('Forms & Procedures', '', 'fal fa-file-alt', '', [], [
                //     new AppMenuItem('Overview', '', 'fal fa-folder-tree', '/app/main/dashboard'),
                //     new AppMenuItem('Transition Docs', '', 'fal fa-file-word', '',[],[
                //         new AppMenuItem('List', '', 'fal fa-list-alt', '/app/main/dashboard')
                //     ]),
                //     new AppMenuItem('Audit Forms', '', 'fal fa-file-user', '/app/main/dashboard'),
                //     new AppMenuItem('Auditor Handbook', '', 'fal fa-book-user', '/app/main/dashboard'),
                //     new AppMenuItem('Teaming sessions', '', 'fal fa-user-chart', '/app/main/dashboard'),
                //     new AppMenuItem('Bulletins & Security', '', 'fal fa-newspaper', '/app/main/dashboard'),
                //     new AppMenuItem('Webinars', '', 'fal fa-camera-home', '/app/main/dashboard'),
                //     new AppMenuItem('Other Forms', '', 'fal fa-file', '/app/main/dashboard'),
                //     new AppMenuItem('Marks', '', 'fal fa-stamp', '/app/main/dashboard'),
                //     new AppMenuItem('Maintenance', '', 'fal fa-cog', '/app/main/dashboard'),
                //     new AppMenuItem('Latest News', '', 'fal fa-bullhorn', '/app/main/dashboard'),
                //     new AppMenuItem('Upcoming Events', '', 'fal fa-calendar-star', '/app/main/dashboard'),
                //     new AppMenuItem('Standards', '', 'fal fa-clipboard-list', '/app/main/dashboard'),
                //     new AppMenuItem('NQA Canada Audit Forms', '', 'fab fa-canadian-maple-leaf', '/app/main/dashboard')
                // ]),
                new AppMenuItem('Reports', '', 'fal fa-file-invoice', '/app/report/list')
            ]),
            new AppMenuItem('Reports', '', 'fal fa-file-invoice', '', [], [
                new AppMenuItem('Dashboard', '', 'fal fa-chart-line', '/app/report/report-dashboard/dashboard'),
                new AppMenuItem('List', '', 'fal fa-list-alt', '/app/report/list'),
                new AppMenuItem('CTO Statistics', '', 'fal fa-analytics', '/app/statistic/list'),
                new AppMenuItem('Operations Statistics', '', 'fal fa-analytics', '/app/statistic/list'),
                new AppMenuItem('CSR Statistics', '', 'fal fa-analytics', '/app/statistic/list'),
                new AppMenuItem('Accounting Statistics', '', 'fal fa-analytics', '/app/statistic/list'),
                new AppMenuItem('Standard Upgrade Statistics', '', 'fal fa-analytics', '/app/statistic/list'),
                new AppMenuItem('Task Manager Statistics', '', 'fal fa-analytics', '/app/statistic/list')
            ]),
            new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [], [
                new AppMenuItem('Configuration', 'Pages.Nqa.Config', 'fal fa-cogs', '/app/config/config'),
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages', ['/app/admin/languages/{name}/texts']),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'flaticon2-world', '/app/admin/webhook-subscriptions'),
                new AppMenuItem('DynamicProperties', 'Pages.Administration.DynamicProperties', 'flaticon-interface-8', '/app/admin/dynamic-property'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
            ])
            //new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components'),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach(menuItem => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach(subMenu => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
