import { Component, Injector, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyPopupService } from '@app/shared/common/company-popup/company-popup.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import CustomStore from 'devextreme/data/custom_store';
import { ContractReviewService } from '../shared/services/contract-review.service';

@Component({
  selector: 'app-contract-review-grid',
  templateUrl: './contract-review-grid.component.html',
  styleUrls: ['./contract-review-grid.component.css']
})
export class ContractReviewGridComponent extends AppComponentBase implements OnInit {

  // @Input('contractReviewList') contractReviewList;
  @Input('clientId') clientId;
  searchString = '';
  contractReviewList: any = [];

  constructor(private injector: Injector,
    private router: Router,
    private companyPopup: CompanyPopupService,
    private contractReviewService: ContractReviewService) { super(injector); }

  ngOnInit(): void {
    this.getContractReviewList();
  }

  editContractReview = (e) => {
    if (this.clientId) {
      this.router.navigate([`app/quote/contractreview/edit/${this.clientId}/${e.row.data.id}`]);
    }
    else {
      this.router.navigate([`app/quote/contractreview/edit/${e.row.data.id}`]);
    }
  }

  createContractReview() {
    this.router.navigate([`app/quote/contractreview/add`])
  }


  deleteContractReview = (e) => {
    var self = this;
    var deleteContractReview = e.row.data;
    deleteContractReview.deleterUserId = this.appSession.user.id;
    deleteContractReview.deletionTime = String(new Date());
    deleteContractReview.isDeleted = true;
    abp.message.confirm(
      'Contract Review will be deleted.',
      'Are you sure?',
      function (isConfirmed) {
        if (isConfirmed) {
          self.contractReviewService.deleteContractReview(deleteContractReview).subscribe(
            (data: any) => {
              var result = data;
              abp.notify.success("", "Contract Review deleted successfully", { showCloseButton: true, timer: 10000 });
              self.getContractReviewList();
            }, (error: any) => {
              abp.log.error(error);
              abp.notify.error("", "Failed to delete Contract Review", { showCloseButton: true, timer: 10000 });
            }
          )
        }
      }
    );

  }

  viewContractReview = (id) => {
    if (this.clientId) {
      this.router.navigate([`app/quote/contractreview/edit/${this.clientId}/${id}`]);
    }
    else {
      this.router.navigate([`app/quote/contractreview/edit/${id}`]);
    }
  }


  getContractReviewList() {
    var self = this;
    this.contractReviewList = new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        const searchString = loadOptions.filter ? (loadOptions.filter[0].filterValue + '').trim() : ""
        let dto = {
          searchString: searchString,
          pageSize: loadOptions.take,
          pageNumber: (self.searchString != searchString) ? 1 : (loadOptions.skip / loadOptions.take) + 1,
          userId: self.appSession.user.id
        }
        let searchDto;
        if (self.clientId) {
          searchDto = {
            ...dto,
            clientId: self.clientId
          }
        }
        else {
          searchDto = dto;
        }
        self.spinnerService.show();
        self.searchString = searchDto.searchString;

        return self.contractReviewService.getContractReviewListUsingServerSidePagination(searchDto)
          .toPromise()
          .then((data: any) => {
            self.spinnerService.hide();
            if (!self.clientId) {

            }
            return {
              data: data.contractReviewList ? data.contractReviewList : [],
              totalCount: data.totalItemsCount
            };
          })
          .catch(error => {
            self.spinnerService.hide();
            abp.log.error(error);
            abp.notify.error("", "Failed to retrieve contract review list", { showCloseButton: true, timer: 10000 });
          });
      }
    });
  }
  openClientPopUp(clientId) {
    this.companyPopup.showCompanyPopup(clientId);
  }

  onToolbarPreparing(e) {
    // e.toolbarOptions.items.push({
    //   location: 'after',
    //   widget: 'dxButton',
    //   options: {
    //     icon: "fa fa-plus",
    //     text: 'Add',
    //     onClick: this.addContractReview.bind(this)
    //   }
    // });
  }

  addContractReview() {
    if (this.clientId) {
      this.router.navigate([`app/quote/contractreview/add/${this.clientId}`]);
    }
    else {
      this.router.navigate([`app/quote/contractreview/add`]);
    }

  }
}
