<p-autoComplete class="company-typeahead search-input-autocomplete form-control" [(ngModel)]="filterText" [type]="'search'"
    [suggestions]="companyTypeaheadSuggestions"  (completeMethod)="onChangeSearch($event)" field="name"
    placeholder="Search…" [minLength]="1" [disabled]="readOnly" virtualScroll = "true" itemSize = "4"
    inputStyleClass="form-control search-typeahead-input" (onSelect)="onSearchItemSelect($event)" #autocomplete
    (onClear)="clearControl(autocomplete)" [ngStyle]="{'height': 'auto !important'}"
    styleClass="width-percent-100">
    <ng-template let-item pTemplate="item">
        <div class="search-suggestion-item">
            <div *ngIf="item.name" class="d-flex justify-content-between align-items-center">
                <div>
                    <b [innerHTML]="item.name | highlight: filterCompany"></b>
                    <div *ngIf="item.primaryAddressAsString">
                        <span [innerHTML]="item.primaryAddressAsString | highlight: filterCompany"></span>
                    </div>
                    <div *ngIf="item.primaryAddressAsString==undefined || item.primaryAddressAsString.trim() == ''">
                        <span>(No Main Address Provided)</span>
                    </div>
                </div>
                <span *ngIf="showClientIcon">
                    <i *ngIf="item.clientId" class="fa-solid fa-pen"></i> 
                    <i *ngIf="!item.clientId" class="fa fa-minus ml-1"></i> 
                </span>
            </div>
            <!-- <div *ngIf="item.primaryAddressAsString">
                <span [innerHTML]="item.primaryAddressAsString | highlight: filterCompany"></span>
            </div>
            <div *ngIf="item.primaryAddressAsString==undefined || item.primaryAddressAsString.trim() == ''">
                <span>(No Main Address Provided)</span>
            </div> -->
        </div>
    </ng-template>
</p-autoComplete>
<!-- (onFocus)="autocomplete.handleDropdownClick($event)" 
     -->
 <!-- [ngClass]="{'search-item-selected': (filterText!=null && item.id===filterText.id)}" -->
