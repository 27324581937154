import { Component, HostListener, Injector, OnInit, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { SubscriptionStartType } from '@shared/service-proxies/service-proxies';
import { ChatSignalrService } from 'app/shared/layout/chat/chat-signalr.service';
import { AppComponentBase } from 'shared/common/app-component-base';
import { SignalRHelper } from 'shared/helpers/SignalRHelper';
import { LinkedAccountsModalComponent } from '@app/shared/layout/linked-accounts-modal.component';
import { UserDelegationsModalComponent } from '@app/shared/layout/user-delegations-modal.component';
import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { NotificationSettingsModalComponent } from '@app/shared/layout/notifications/notification-settings-modal.component';
import { UserNotificationHelper } from '@app/shared/layout/notifications/UserNotificationHelper';
import { DateTime } from 'luxon';
import { DateTimeService } from './shared/common/timing/date-time.service';
import { fromEvent } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent extends AppComponentBase implements OnInit {

    subscriptionStartType = SubscriptionStartType;
    theme: string;
    installationMode = true;

    @ViewChild('loginAttemptsModal', { static: true }) loginAttemptsModal: LoginAttemptsModalComponent;
    @ViewChild('linkedAccountsModal') linkedAccountsModal: LinkedAccountsModalComponent;
    @ViewChild('userDelegationsModal', { static: true }) userDelegationsModal: UserDelegationsModalComponent;
    @ViewChild('changePasswordModal', { static: true }) changePasswordModal: ChangePasswordModalComponent;
    @ViewChild('changeProfilePictureModal', { static: true }) changeProfilePictureModal: ChangeProfilePictureModalComponent;
    @ViewChild('mySettingsModal', { static: true }) mySettingsModal: MySettingsModalComponent;
    @ViewChild('notificationSettingsModal', { static: true }) notificationSettingsModal: NotificationSettingsModalComponent;
    @ViewChild('chatBarComponent') chatBarComponent;
    isQuickThemeSelectEnabled: boolean = this.setting.getBoolean('App.UserManagement.IsQuickThemeSelectEnabled');
    IsSessionTimeOutEnabled: boolean = this.setting.getBoolean('App.UserManagement.SessionTimeOut.IsEnabled') && this.appSession.userId != null;

    private unsavedChanges: boolean = false;
    private routetoSameURL: boolean = false;
    public constructor(
        injector: Injector,
        private router: Router,
        private _chatSignalrService: ChatSignalrService,
        private _userNotificationHelper: UserNotificationHelper,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);
    }

    // return abp.message.confirm(
    //     'You have unsaved changes',
    //     'Do you want to continue?',
    //     (isConfirmed) => isConfirmed
    // );

    ngOnInit(): void {
        this._userNotificationHelper.settingsModal = this.notificationSettingsModal;
        this.theme = abp.setting.get('App.UiManagement.Theme').toLocaleLowerCase();
        this.installationMode = UrlHelper.isInstallUrl(location.href);

        this.registerModalOpenEvents();

        if (this.appSession.application) {
            // SignalRHelper.initSignalR(() => { this._chatSignalrService.init(); });
        }
        this.subscribeToKeyUp();
        this.subscribeToRouterEvents();
        //window.addEventListener('beforeunload', this.onBeforeUnload.bind(this));
        this.subscribeToUnload();
    }

    onBeforeUnload(event: BeforeUnloadEvent): void {
        event.returnValue = 'Are you sure you want to leave?';
    }

    private subscribeToKeyUp() {
        const keyUp = fromEvent(document, 'keyup');
        const keyUpEvent = keyUp.pipe(debounceTime(100));

        keyUpEvent.subscribe((e: any) => {
            if (this.isInputKey(e) && e.target.tagName.toLowerCase() === 'input' && !this.isSearchInput(e.target)) {
                this.unsavedChanges = true;
                this.routetoSameURL = false;
            }
        });
    }

    private isSearchInput(element: any): boolean {
        return element.getAttribute('tag') === 'search' || element.getAttribute('aria-autocomplete') === 'list' || element.classList.contains('dx-texteditor-input');
    }

    subscribeToUnload() {
        var self = this;
        window.addEventListener("beforeunload", function (e) {
            if (self.unsavedChanges) {
                const confirmationMessage = 'You have unsaved changes. Do you want to continue?';
                e.returnValue = confirmationMessage;
                const userConfirmed = window.confirm(confirmationMessage);
                if (userConfirmed) {
                    self.unsavedChanges = false;
                }
            }
        });
    }

    ngOnDestroy(): void {
        // Remove the event listener when the component is destroyed
        this.unsavedChanges = false;
        window.removeEventListener('beforeunload', this.onBeforeUnload.bind(this));
    }

    @HostListener('document:click', ['$event.target'])
    handleDocumentClicks(target) {
        if (target instanceof HTMLElement) {
            const isSubmitButton = target.tagName === 'BUTTON' && target.getAttribute('type') === 'submit';
            const isSaveButton = (target.tagName === 'BUTTON' && (target.innerText.trim().includes('Save')
                || target.innerText.trim() === 'Save and Publish'
                || target.innerText.trim() === 'Publish and Add Another'
                || target.innerText.trim().includes('Add')
                || target.innerText.trim().includes('Update')
                )) || (target.tagName === 'SPAN' && (target.innerText.trim() === 'Add') || (target.innerText.trim() === 'Save') || target.innerText.trim().includes('Update'));

            const isSearchButton = target.tagName === 'BUTTON' && target.innerText.trim() === 'Search';
            const isClearButton = target.tagName === 'BUTTON' && target.innerText.trim() === 'Clear';

            if (isSubmitButton || isSaveButton || isSearchButton || isClearButton) {
                this.unsavedChanges = false;
            }
        }
    }

    private subscribeToRouterEvents() {
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                if (this.unsavedChanges && !this.routetoSameURL) {
                    const isConfirmed = window.confirm('You have unsaved changes. Do you want to continue?');
                    if (!isConfirmed) {
                        this.routetoSameURL = true;
                        this.router.navigateByUrl(this.router.url);
                    } else {
                        this.routetoSameURL = false;
                    }
                }
            });
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                if (!this.routetoSameURL) {
                    this.unsavedChanges = false;
                }
                else {
                    this.routetoSameURL = false;
                }
            });
    }

    private isInputKey(event: KeyboardEvent): boolean {
        const inputKeys = /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?/~\\s\s]*$/;
        return (
            (event.key && event.key.length === 1 && inputKeys.test(event.key)) ||
            event.code === 'Backspace' ||
            event.code === 'Delete' ||
            event.code === 'Enter'
        );
    }

    subscriptionStatusBarVisible(): boolean {
        return this.appSession.tenantId > 0 &&
            (this.appSession.tenant.isInTrialPeriod ||
                this.subscriptionIsExpiringSoon());
    }

    subscriptionIsExpiringSoon(): boolean {
        if (this.appSession.tenant.subscriptionEndDateUtc) {
            let today = this._dateTimeService.getUTCDate();
            let daysFromNow = this._dateTimeService.plusDays(today, AppConsts.subscriptionExpireNootifyDayCount);
            return daysFromNow >= this.appSession.tenant.subscriptionEndDateUtc;
        }

        return false;
    }

    registerModalOpenEvents(): void {
        abp.event.on('app.show.loginAttemptsModal', () => {
            this.loginAttemptsModal.show();
        });

        abp.event.on('app.show.linkedAccountsModal', () => {
            this.linkedAccountsModal.show();
        });

        abp.event.on('app.show.userDelegationsModal', () => {
            this.userDelegationsModal.show();
        });

        abp.event.on('app.show.changePasswordModal', () => {
            this.changePasswordModal.show();
        });

        abp.event.on('app.show.changeProfilePictureModal', () => {
            this.changeProfilePictureModal.show();
        });

        abp.event.on('app.show.mySettingsModal', () => {
            this.mySettingsModal.show();
        });
    }

    getRecentlyLinkedUsers(): void {
        abp.event.trigger('app.getRecentlyLinkedUsers');
    }

    onMySettingsModalSaved(): void {
        abp.event.trigger('app.onMySettingsModalSaved');
    }
}
