import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-quotes-awaiting-contract',
  templateUrl: './widget-quotes-awaiting-contract.component.html',
  styleUrls: ['./widget-quotes-awaiting-contract.component.css']
})
export class WidgetQuotesAwaitingContractComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
