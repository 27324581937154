<div id="outbound-messages-wrapper">
    <div class="search-wrapper">
        <div class="row mlr-8">
            <div class="col-lg-4 col-md-3 plr-8 align-self-center order-1 order-md-0">
                <div class="filter-btn" (click)="toggleFilter()">
                    <span>{{showFilter ? 'Hide':'Show'}} Filters</span>
                    <span *ngIf="!showFilter"><i class="fa fa-angle-down"></i></span>
                    <span *ngIf="showFilter"><i class="fa fa-angle-up"></i></span>
                </div>
            </div>
            <div class="col-lg-8 col-md-9 plr-8 order-0 order-md-1">
                <div class="search-field-wrapper">
                    <input class="form-control"/>
                    <button class="btn primary-button export-button">Export</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showFilter" class="filter-wrapper">
        <div class="row mlr-8">
            <div class="col-xl-2 col-sm-4 plr-8">
                <div class="form-group">
                    <label>Receipent</label>
                    <input class="form-control" [(ngModel)]="receipentFilter"/>
                </div>
            </div>
            <div class="col-xl-2 col-sm-4 plr-8">
                <div class="form-group">
                    <label>From Email</label>
                    <input class="form-control" type="email" [(ngModel)]="fromEmail"/>
                </div>
            </div>
            <div class="col-xl-2 col-sm-4 plr-8">
                <div class="form-group">
                    <label>Subject</label>
                    <input class="form-control"  [(ngModel)]="subject"/>
                </div>
            </div>
            <div class="col-xl-2 col-sm-4 plr-8">
                <div class="form-group">
                    <label>Status</label>
                    <input class="form-control" [(ngModel)]="status"/>
                </div>
            </div>
            <div class="col-xl-2 col-sm-4 plr-8">
                <div class="form-group">
                    <label>From Date</label>
                    <div class="input-datepicker">
                        <input class="form-control"  type="text" bsDatepicker datePickerLuxonModifier [bsConfig]="{ adaptivePosition: true, showWeekNumbers:false }" [(ngModel)]="fromDate"/>
                       <i class="fa-solid fa-calendar-days"></i>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-sm-4 plr-8">
                <div class="form-group">
                    <label>To Date</label>
                    <div class="input-datepicker">
                        <input class="form-control" type="text" bsDatepicker datePickerLuxonModifier [bsConfig]="{ showWeekNumbers:false }" placement="top right" [(ngModel)]="toDate"/>
                       <i class="fa-solid fa-calendar-days"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right btn-wrapper">
            <button class="btn primary-button" (click)="search()">Search</button>
            <button class="btn secondary-button" (click)="reset()">Clear</button>
        </div>
    </div>
    <div class="list-wrapper">
        <dx-data-grid [dataSource]="messageList" [rowAlternationEnabled]="false" [showColumnLines]="false"
            [columnHidingEnabled]="false" [showRowLines]="true" [showBorders]="true" [wordWrapEnabled]="true">
            <!-- <dxo-search-panel [visible]="true" [width]="320"></dxo-search-panel> -->
            <dxi-column [width]="100"  caption="Event" dataField="Status" alignment="center" cellTemplate="viewStatusTemplate">
            </dxi-column>
            <div *dxTemplate="let cell of 'viewStatusTemplate'">
                <span *ngIf="cell.data.Status === 'Sent'" style="background: green;" class="tag-style">{{cell.data.Status}}</span>
                <span *ngIf="cell.data.Status === 'Opened'" style="background: rgb(60, 194, 247);" class="tag-style">{{cell.data.Status}}</span>
                <span *ngIf="cell.data.Status === 'Delivered'" style="background: green;" class="tag-style">{{cell.data.Status}}</span>
            </div>
            <dxi-column caption="Recipient" dataField="To" cellTemplate="viewCellTemplate" alignment="left"></dxi-column>
            <div *dxTemplate="let cell of 'viewCellTemplate'">
                <a *ngFor="let item of cell.data.To; let i = index" (click)="onMessageClick(cell.data)">
                    {{item.Email}}
                </a>
            </div>
            <dxi-column caption="Subject" dataField="Subject" alignment="left">
            </dxi-column>
            <dxi-column caption="Tags" [width]="200" dataField="Tags" alignment="left">
            </dxi-column>
            <dxi-column caption="Date & Time" [width]="120" dataField="ReceivedAt" alignment="left" cellTemplate="viewDateTemplate">
            </dxi-column>
            <div *dxTemplate="let cell of 'viewDateTemplate'">
                <span>{{formatDate(cell.data)}}</span>
            </div>
            <!-- <dxi-column type="buttons" caption="" [width]="80">
                <dxi-button text="Edit" icon="edit" [onClick]="editCode">
                </dxi-button>
                <dxi-button text="Delete" icon="trash" [onClick]="deleteCode">
                </dxi-button>
            </dxi-column> -->
        </dx-data-grid>
    </div>
</div>

<p-sidebar id="outbound-message-sidebar" [(visible)]="showMessageDetail" blockScroll="true" [dismissible]="true">
    <div class="detail-wrapper">
        <div class="row">
            <div class="col-md-3" style="text-align: right;">
                <label>Subject:</label>
            </div>
            <div class="col-md-9">
                <label>{{messageDetail.Subject}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3" style="text-align: right;">
                <label>From:</label>
            </div>
            <div class="col-md-9">
                <label>{{messageDetail.From}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3" style="text-align: right;">
                <label>Open Tracking:</label>
            </div>
            <div class="col-md-9">
                <label *ngIf="messageDetail.TrackOpens" style="color: green;"><i class="fa fa-check-circle"></i>&nbsp;Enabled</label>
                <label *ngIf="!messageDetail.TrackOpens" style="color: red;"><i class="fa fa-times-circle-o"></i>&nbsp;Disabled</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3" style="text-align: right;">
                <label>Link Tracking:</label>
            </div>
            <div class="col-md-9">
                <label *ngIf="messageDetail.TrackLinks === 'HtmlAndText'" style="color: green;"><i class="fa fa-check-circle"></i>&nbsp;Enabled for HTML and Texts</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3" style="text-align: right;">
                <label>To:</label>
            </div>
            <div class="col-md-9">
                <div class="to-wrapper" *ngFor="let receipent of messageDetail.MessageEvents">
                    <div class="email-wrapper row" style="width: 300px;">
                        <div class="icon" style="max-width: 100px;">
                            <div class="text-icon">{{getFirstCharacter(receipent.Recipient)}}</div>
                        </div>
                        <div class="col-md-8">
                            <p style="margin: 0; font-size: 12px;">{{receipent.Recipient}}</p>
                            <span style="font-size: 10px;">{{receipent.Type}}</span>
                        </div>
                    </div>
                    <div class="status-wrapper row">
                        <div class="dot"></div>
                        <div class="text-container">
                            <div class="text-status">{{receipent.Type}}</div>
                            <div>{{formatDate(receipent.ReceivedAt)}}</div>
                        </div>
                        <div class="big-text">
                            <p>{{receipent.Details.DeliveryMessage || receipent.Details.Summary}}</p>
                        </div>
                    </div>
                    <!-- <div class="status-wrapper row">
                        <div class="dot"></div>
                        <div class="text-container">
                            <div class="text-status">Proccessed</div>
                            <div>DEC 1&nbsp; 12:36 PM</div>
                        </div>
                        <div class="big-text">
                            <p>This is Proccessed</p>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="wizard-wrapper">
            <tabset>
                <tab heading="{{'HTML'}}">
                    <div class="tab-wrapper" style="overflow: auto;">
                        <div [innerHTML]="messageDetail.HtmlBody"></div>
                    </div>
                </tab>
                <tab heading="{{'Plain Text'}}">
                    <div class="tab-wrapper">
                        <div>{{messageDetail.TextBody}}</div>
                    </div>
                </tab>
                <!-- <tab heading="{{'Source'}}">
                    <div class="tab-wrapper">
                        <div>{{messageDetail.HtmlBody}}</div>
                    </div>
                </tab> -->
            </tabset>
        </div>
    </div>
</p-sidebar>
