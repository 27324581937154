import { NgModule } from '@angular/core';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { NgxSpinnerService } from 'ngx-spinner';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'app',
                component: AppComponent,
                canActivate: [AppRouteGuard],
                canActivateChild: [AppRouteGuard],
                children: [
                    {
                        path: '',
                        children: [
                            { path: 'notifications', component: NotificationsComponent },
                            { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' }
                        ]
                    },
                    {
                        path: 'main',
                        loadChildren: () => import('app/main/main.module').then(m => m.MainModule), //Lazy load main module
                        data: { preload: true }
                    },
                    {
                        path: 'config',
                        loadChildren: () => import('app/config/config.module').then(m => m.ConfigModule), //Lazy load config module
                        data: { preload: true }
                    },
                    {
                        path: 'admin',
                        loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule), //Lazy load admin module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'company',
                        loadChildren: () => import('app/company/company.module').then(m => m.CompanyModule), //Lazy load company module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'person',
                        loadChildren: () => import('./person/person.module').then(m => m.PersonModule), //Lazy load person module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'sales',
                        loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule), //Lazy load sales module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'finance',
                        loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule), //Lazy load finance module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'quality',
                        loadChildren: () => import('./quality/quality.module').then(m => m.QualityModule), //Lazy load quality module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'client',
                        loadChildren: () => import('./client/client.module').then(m => m.ClientModule), //Lazy load client module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'auditor',
                        loadChildren: () => import('./auditor/auditor.module').then(m => m.AuditorModule), //Lazy load auditor module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'contact',
                        loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule), //Lazy load contact module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'schedule',
                        loadChildren: () => import('./schedule/schedule.module').then(m => m.ScheduleModule), //Lazy load schedule module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'certificate',
                        loadChildren: () => import('./certificate/certificate.module').then(m => m.CertificateModule), //Lazy load certificate module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                     },
                    {
                        path: 'document',
                        loadChildren: () => import('./document/document.module').then(m => m.DocumentModule), //Lazy load document module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'report',
                        loadChildren: () => import('./report/report.module').then(m => m.ReportModule), //Lazy load report module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'payroll',
                        loadChildren: () => import('./payroll/payroll.module').then(m => m.PayrollModule), //Lazy load payroll module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'people',
                        loadChildren: () => import('./people/people.module').then(m => m.PeopleModule), //Lazy load people module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'billing',
                        loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule), //Lazy load billing module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'fo',
                        loadChildren: () => import('./fo/fo.module').then(m => m.FoModule), //Lazy load fo module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'quote',
                        loadChildren: () => import('./quote/quote.module').then(m => m.QuoteModule), //Lazy load quote module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'cpars',
                        loadChildren: () => import('./cpars/cpars.module').then(m => m.CparsModule), //Lazy load quote module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'contract',
                        loadChildren: () => import('./contract/contract.module').then(m => m.ContractModule), //Lazy load contract module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'backlog',
                        loadChildren: () => import('./backlog/backlog.module').then(m => m.BacklogModule), //Lazy load backlog module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'time-expense'
                        , loadChildren: () => import('./time-expense/time-expense.module').then(m => m.TimeExpenseModule), //Lazy load ('./ module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'ncr',
                        loadChildren: () => import('./ncr/ncr.module').then(m => m.NcrModule), //Lazy load ncr module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'checklist',
                        loadChildren: () => import('./checklist/checklist.module').then(m => m.ChecklistModule),
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'quality-manual'
                        , loadChildren: () => import('./quality-manual/quality-manual.module').then(m => m.QualityManualModule), //Lazy load ('./ module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'capa',
                        loadChildren: () => import('./capa/capa.module').then(m => m.CapaModule), //Lazy load capa module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'form-procedure'
                        , loadChildren: () => import('./form-procedure/form-procedure.module').then(m => m.FormProcedureModule), //Lazy load ('./ module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'statistic',
                        loadChildren: () => import('./statistic/statistic.module').then(m => m.StatisticModule), //Lazy load statistic module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'onboard',
                        loadChildren: () => import('./onboard/onboard.module').then(m => m.OnboardModule), //Lazy load onboard module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'prospect',
                        loadChildren: () => import('./prospect/prospect.module').then(m => m.ProspectModule), //Lazy load prospect module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'salesforce',
                        loadChildren: () => import('./salesforce/salesforce.module').then(m => m.SalesforceModule),
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    { 
                        path: 'file-manager', 
                        loadChildren: () => import('./file-manager/file-manager.module').then(m => m.FileManagerModule),
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                     },
                    {
                        path: '**', redirectTo: 'notifications'
                    }
                ]
            }            
        ])
    ],
    exports: [RouterModule]
})

export class AppRoutingModule {
    constructor(
        private router: Router,
        private spinnerService: NgxSpinnerService
    ) {
        router.events.subscribe((event) => {

            if (event instanceof RouteConfigLoadStart) {
                spinnerService.show();
            }

            if (event instanceof RouteConfigLoadEnd) {
                spinnerService.hide();
            }

            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url').setAttribute('content', window.location.href);
            }
        });
    }
}
