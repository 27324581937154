<div class="audit-grid-container">
    <dx-data-grid class="dx-grid-max-height" [dataSource]="auditList" [rowAlternationEnabled]="false"
        [showColumnLines]="false" [columnHidingEnabled]="true" [showRowLines]="true" [showBorders]="true"
        [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)" [remoteOperations]="true">
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-search-panel [visible]="true" [width]="100+'%'" [(text)]="searchString"></dxo-search-panel>
        <dxo-state-storing [enabled]="true" type="localStorage" storageKey="auditListStorageName">
        </dxo-state-storing>
        <div *dxTemplate="let cell of 'viewIcon'">
            <a (click)="viewAuditDetails(cell.data)" class="a-link" target="_blank">{{ cell.text }}</a>
        </div>
        <dxi-column caption="Audit Id" dataField="id" [width]="120" alignment="center" cellTemplate="viewIcon"></dxi-column>
        <dxi-column caption="Audit#" dataField="auditNumber" [width]="120" alignment="center" cellTemplate="viewIcon"></dxi-column>
        <dxi-column caption="Customer#" dataField="companyId" [width]="120" alignment="center"></dxi-column>
        <dxi-column caption="Customer" dataField="companyName" [width]="200" alignment="left"></dxi-column>
        <dxi-column caption="Location" dataField="locationAddress" [width]="220" alignment="left"></dxi-column>
        <dxi-column caption="Audit Group" dataField="auditPlan" alignment="left"></dxi-column>
        <dxi-column caption="Visit Type" dataField="auditTypeName" [width]="120" alignment="center"></dxi-column>
        <dxi-column caption="Due Date" dataField="dueDate" [width]="100" alignment="center"
            cellTemplate="dateTemplate"></dxi-column>
        <dxi-column caption="Start Date" dataField="startDate" [width]="100" alignment="center"
            cellTemplate="dateTemplate"></dxi-column>
        <dxi-column caption="End Date" dataField="endDate" [width]="100" alignment="center"
            cellTemplate="dateTemplate"></dxi-column>
        <dxi-column caption="Audit" dataField="durationDeviationDays" [width]="100" alignment="center"
            cellTemplate="durationTemplate"></dxi-column>
        <div *dxTemplate="let cell of 'durationTemplate'">
            <div> {{cell.text}}&nbsp;Days</div>
        </div>
        <div *dxTemplate="let cell of 'dateTemplate'">
            <div> {{getLocaleDate(cell.text)}}</div>
        </div>
        <div *dxTemplate="let data of 'pick-audit-location'">
            <label>Locations</label>
            <ng-select [selectOnTab]="true" [items]="locationList" bindLabel="locationName" bindValue="companyLocationId"
                [ngModel]="companyLocationId" (clear)="clearLocationSelection();"
                (ngModelChange)="onAuditLocationChange($event)" [searchable]="false" [clearable]="true">
            </ng-select>
        </div>
        <dxo-paging [pageSize]="200"></dxo-paging>
        <dxo-pager [showInfo]="true">
        </dxo-pager>
    </dx-data-grid>
</div>
