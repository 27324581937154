import { Component, Injector, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import CustomStore from 'devextreme/data/custom_store';
import * as moment from 'moment';
import { AuditService } from '../shared/services/audit.service';

@Component({
  selector: 'app-audit-grid',
  templateUrl: './audit-grid.component.html',
  styleUrls: ['./audit-grid.component.css']
})
export class AuditGridComponent extends AppComponentBase implements OnInit {

  @Input('clientId') clientId;
  @Input('locationList') locationList;
  searchString = '';
  auditList: any = [];
  companyLocationId;
  isView: boolean = window.location.pathname.indexOf('/view') === -1 ? false : true;

  constructor(private injector: Injector,
    private auditService: AuditService,
    private router: Router) {
    super(injector);
  }

  ngOnInit(): void {
    this.getAuditList();
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.companyLocationId) {
  //     this.getAuditList();
  //   }
  // }

  editAudit = (e) => {
    this.router.navigate([`app/schedule/${e.row.data.id}/edit`])
  }

  viewAuditDetails(data: any) {
    this.router.navigate([`app/schedule/${data.id}/view`]);
  }

  getLocaleDate(date) {
    if (date) {
      return moment(date).format(AppConsts.momentDateOnly);
    }
    else {
      return "";
    }
  }

  getAuditList() {
    var self = this;
    this.auditList = new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        const searchString = loadOptions.filter ? (loadOptions.filter[0].filterValue + '').trim() : ""
        let dto = {
          searchString: searchString,
          pageSize: loadOptions.take,
          pageNumber: (self.searchString != searchString) ? 1 : (loadOptions.skip / loadOptions.take) + 1,
          userId: self.appSession.user.id
        }
        let searchDto;
        if (self.clientId) {
          searchDto = {
            ...dto,
            clientId: self.clientId
          }
        }
        else {
          searchDto = dto;
        }

        if (self.companyLocationId) {
          searchDto = {
            ...searchDto,
            companyLocationId: self.companyLocationId
          }
        }
        self.spinnerService.show();
        self.searchString = searchDto.searchString;

        return self.auditService.getAuditListByClientUsingServerSidePagination(searchDto)
          .toPromise()
          .then((data: any) => {
            const result = data;
            self.spinnerService.hide();
            if (!self.clientId) {

            }
            return {
              data: data.auditList ? data.auditList : [],
              totalCount: data.totalItemsCount
            };
          })
          .catch(error => {
            self.spinnerService.hide();
            abp.log.error(error);
            abp.notify.error("", "Failed to retrieve audit list", { showCloseButton: true, timer: 10000 });
          });
      }
    });
  }

  addSchedule() {
    // localStorage.setItem(AppConsts.localStorageKeys.scheduleDetailsFromPageKey, ManageAuditsFromPage.unScheduledAudits);
    if (this.clientId) {
      this.router.navigate([`app/schedule/addSchedule/${this.clientId}`]);
    }
    else {
      this.router.navigate([`app/quote/add`]);
    }
  }

  onAuditLocationChange(data) {
    this.companyLocationId = data;
    this.getAuditList();
  }

  clearLocationSelection() {
    this.companyLocationId = null;
    this.getAuditList();
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: 'before',
      template: 'pick-audit-location',
      options: {
        width: 200
      }
    });
    e.toolbarOptions.items.push({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: "fa fa-plus",
        text: 'Add',
        onClick: this.addSchedule.bind(this)
      }
    });
  }

}
