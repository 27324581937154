import { Injectable } from '@angular/core';
import { HttpBaseService } from '../httpbase.service';
import { Observable } from 'rxjs';
import { CompanyType, ReorderCompanyType } from '@app/shared/models/config/company-type.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyTypeService {

  constructor(private httpBaseService: HttpBaseService) { }

  public getAllCompanyTypes(userId): Observable<any> {
    const apiUrl = 'CompanyType/GetCompanyTypes/'+ userId
    return this.httpBaseService.get(apiUrl);
  }
  createCompanyType(companyType: CompanyType): Observable<any> {
    return this.httpBaseService.post('CompanyType/CreateCompanyType', companyType);
  }
  updateCompanyType(companyType: CompanyType): Observable<any> {
    return this.httpBaseService.post('CompanyType/UpdateCompanyType', companyType);
  }
  deleteCompanyType(companyType: CompanyType): Observable<any> {
    return this.httpBaseService.post('CompanyType/DeleteCompanyType', companyType);
  }
  reorderCompanyType(companyType: ReorderCompanyType): Observable<any> {
    return this.httpBaseService.post('CompanyType/ReorderCompanyTypes', companyType);
  }

}
