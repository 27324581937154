import { Injectable } from '@angular/core';
import { HttpBaseService } from '../httpbase.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OutboundMessageService {

    constructor(private http: HttpClient) { }

    public GetOutboundMessages(): Observable<any> {
        const apiUrl = 'messages/outbound?count=500&offset=0'
        return this.http.get(environment.outboundMessageApiBaseUrl + apiUrl, { headers: environment.outboundMessageHeaders });
    }

    public GetOutboundMessagesDetail(id: string): Observable<any> {
        const apiUrl = `messages/outbound/${id}/details`
        return this.http.get(environment.outboundMessageApiBaseUrl + apiUrl, { headers: environment.outboundMessageHeaders });
    }

    public GetOutboundMessagesWithParams(params: any): Observable<any> {
        const apiUrl = `messages/outbound?count=500&offset=0&${params}`
        return this.http.get(environment.outboundMessageApiBaseUrl + apiUrl, { headers: environment.outboundMessageHeaders });
    }
}
