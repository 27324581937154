import { Component, OnInit, Injector, Input } from '@angular/core';
import { QuoteService } from '../shared/services/quote.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Router } from '@angular/router';
import { DeleteRequest } from '@app/shared/models/delete-request';
import { CompanyPopupService } from '@app/shared/common/company-popup/company-popup.service';
import CustomStore from 'devextreme/data/custom_store';
import { SearchDto } from '@app/shared/models/pagination/search-dto';
import { DataService } from '@app/shared/services/data/data.service';

@Component({
  selector: 'app-quote-grid',
  templateUrl: './quote-grid.component.html',
  styleUrls: ['./quote-grid.component.css']
})

export class QuoteGridComponent extends AppComponentBase implements OnInit {

  // @Input('quoteList') quoteList;
  @Input('clientId') clientId;
  searchString = '';
  quoteList: any = [];
  isView: boolean = window.location.pathname.indexOf('/view') === -1 ? false : true;

  constructor(private injector: Injector,
    private router: Router,
    private companyPopup: CompanyPopupService,
    private quoteService: QuoteService,
    private dataService: DataService) { super(injector); }

  ngOnInit(): void {
    this.getQuoteList();
  }

  editQuote = (e) => {
    this.router.navigate([`app/quote/edit/${e.row.data.id}`])
  }

  createQuote() {
    this.router.navigate([`app/quote/add`])
  }


  deleteQuote = (e) => {
    var self = this;
    var deleteQuote = e.row.data;
    deleteQuote.deleterUserId = this.appSession.user.id;
    deleteQuote.deletionTime = String(new Date());
    deleteQuote.isDeleted = true;
    abp.message.confirm(
      'Quote will be deleted.',
      'Are you sure?',
      function (isConfirmed) {
        if (isConfirmed) {
          self.quoteService.deleteQuote(deleteQuote).subscribe(
            (data: any) => {
              var result = data;
              abp.notify.success("", "Quote deleted successfully", { showCloseButton: true, timer: 10000 });
              self.getQuoteList();
            }, (error: any) => {
              abp.log.error(error);
              abp.notify.error("", "Failed to delete Quote", { showCloseButton: true, timer: 10000 });
            }
          )
        }
      }
    );

  }

  viewQuote = (id) => {
    if (this.clientId) {
      this.router.navigate([`app/quote/edit/${this.clientId}/${id}`]);
    }
    else {
      this.router.navigate([`app/quote/edit/${id}`]);
    }
  }


  getQuoteList() {
    var self = this;
    this.quoteList = new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        const searchString = loadOptions.filter ? (loadOptions.filter[0].filterValue + '').trim() : ""
        let dto = {
          searchString: searchString,
          pageSize: loadOptions.take,
          pageNumber: (self.searchString != searchString) ? 1 : (loadOptions.skip / loadOptions.take) + 1,
          userId: self.appSession.user.id
        }
        let searchDto;
        if (self.clientId) {
          searchDto = {
            ...dto,
            clientId: self.clientId
          }
        }
        else {
          searchDto = dto;
        }
        self.spinnerService.show();
        self.searchString = searchDto.searchString;

        return self.quoteService.getQuotesUsingServerSidePaginationByUserId(searchDto)
          .toPromise()
          .then((data: any) => {
            self.spinnerService.hide();
            if (!self.clientId) {

            }
            return {
              data: data.quoteList ? data.quoteList : [],
              totalCount: data.totalItemsCount
            };
          })
          .catch(error => {
            self.spinnerService.hide();
            abp.log.error(error);
            abp.notify.error("", "Failed to retrieve quote list", { showCloseButton: true, timer: 10000 });
          });
      }
    });
  }
  openClientPopUp(clientId) {
    this.companyPopup.showCompanyPopup(clientId);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: "fa fa-plus",
        text: 'Add',
        onClick: this.addQuote.bind(this)
      }
    });
  }

  cloneQuote(event){
    if (this.clientId) {
      // this.dataService.next(this.clientDto);
      this.router.navigate([`app/quote/add/${this.clientId}/clone/${event.id}`]);
    }
    else {
      this.router.navigate([`app/quote/add/clone/${event.id}`]);
    }
  }

  addQuote() {
    if (this.clientId) {
      // this.dataService.next(this.clientDto);
      this.router.navigate([`app/quote/add/${this.clientId}`]);
    }
    else {
      this.router.navigate([`app/quote/add`]);
    }

  }
}

