<div dropdown class="dropdown dropdown-language" aria-haspopup="true" *ngIf="'Pages.Administration' | permission" [dropup]="isDropup" style="height:65px;">
    <div dropdownToggle class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" aria-expanded="false">
        <div [class]="customStyle">
            <i class="flaticon-imac"></i>
        </div>
    </div>
    <div *dropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right">
        <perfect-scrollbar>
        <ul class="navi navi-hover py-4">
            <li class="navi-item" *ngFor="let system of systems">
                <a href="javascript:;" (click)="switchSystem(system.id)" class="navi-link">
                    <span class="navi-text">
                        {{system.Name}}
                    </span>
                </a>
            </li>
        </ul>
    </perfect-scrollbar>
    </div>
</div>
