import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalConstants } from '@app/shared/common/constants/global-constants';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { ChecklistTypeService } from '@app/shared/services/checklist-type/checklist-type.service';
import { JustificationReasonService } from '@app/shared/services/justification-reason/justification-reason.service';
import { JustificationService } from '@app/shared/services/justification/justification.service';
import { StandardCategoryService } from '@app/shared/services/standard-category/standard-category.service';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-justification-modal',
  templateUrl: './justification-modal.component.html',
  styleUrls: ['./justification-modal.component.css']
})
export class JustificationModalComponent extends AppComponentBase implements OnInit {
  @Input('ownerId') ownerId;
  @Input('module') module;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  justificationForm: FormGroup;
  showSideBar: boolean = false;
  submitted: boolean = false;
  modalTitle = 'Add';
  editIndex;
  justificationConsolidated;
  reasonList: any = [];
  fileName = '';
  fileLoading = false;
  reasonProcessedList: any = [];
  standardCategoryList: any = [];

  selectedItem = null;
  constructor(injector: Injector,
    private justificationReasonService: JustificationReasonService,
    private justificationService: JustificationService,
    private standardCategoryService: StandardCategoryService,
    private formBuilder: FormBuilder, private dateTimeService: DateTimeService) {
    super(injector);
    this.justificationForm = this.formBuilder.group({
      rStandardCategory_Id: new FormControl(null, [Validators.required]),
      explanation: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
  }


  show(i): void {
    this.clear();
    this.showSideBar = true;
    this.justificationForm = this.formBuilder.group({
      rStandardCategory_Id: new FormControl(null, [Validators.required]),
      explanation: new FormControl(null, [Validators.required]),
    });

    if (i != 0) {
      this.modalTitle = 'Edit';
      this.selectedItem = i;
    } else {
      this.editIndex = null;
      this.justificationForm.reset();
      this.modalTitle = 'Add';
      this.selectedItem = null;
    }
    this.populateControls();
  }

  get f() {
    return this.justificationForm.controls;
  }

  onFileChange(event) {

  }

  getStandardCategoryObservable(): any {
    switch (this.module) {
      case AppConsts.Module.Client:
        return this.standardCategoryService.getStandardCategoriesAssociatedWithClientCertificate(this.ownerId, this.appSession.user.id);
      case AppConsts.Module.Quote:
        return this.standardCategoryService.getStandardCategoriesAssociatedWithQuote(this.ownerId, this.appSession.user.id);
      case AppConsts.Module.ContractReview:
        return this.standardCategoryService.getStandardCategoriesAssociatedWithContractReview(this.ownerId, this.appSession.user.id);
      default:
        return this.standardCategoryService.getStandardCategories(this.appSession.user.id);
    }
  }

  populateControls() {
    var forkJoinObsCollection = [];

    this.spinnerService.show();

    let justificationReasonObservable = this.justificationReasonService.getJustificationReasons(this.appSession.user.id);
    let standardCategoryObservable = this.getStandardCategoryObservable();


    forkJoinObsCollection = [justificationReasonObservable, standardCategoryObservable];
    if (this.selectedItem) {
      let justificationObservable = this.justificationService.getJustificationById(this.selectedItem.id, this.appSession.user.id);
      forkJoinObsCollection.push(justificationObservable);
    }

    forkJoin(forkJoinObsCollection).subscribe(result => {
      this.reasonList = result[0];
      this.addReasonIdtoList();
      this.standardCategoryList = result[1];
      if (this.selectedItem) {
        this.justificationConsolidated = result[2];
        this.bindConsolidated();
      }
      else {
        this.reasonProcessedList = this.reasonList;
      }
      this.spinnerService.hide();
    },
      error => {
        this.spinnerService.hide();
        abp.log.error(error);
        abp.notify.error("", "Error in populating data", { showCloseButton: true, timer: 10000 });
      });

  }

  addReasonIdtoList() {
    if (this.reasonList && this.reasonList.length > 0) {
      this.reasonList = this.reasonList.map(x => {
        return {
          ...x,
          rJustificationReason_Id: x.id,
        };
      });
    }
  }

  bindConsolidated() {
    this.justificationForm.patchValue({
      rStandardCategory_Id: this.justificationConsolidated?.rStandardCategory_Id,
      explanation: this.justificationConsolidated?.explanation
    });
    if (this.justificationConsolidated?.justificationDetails) {
      this.reasonProcessedList = this.reasonList.map(x => {
        const matchedReason = this.justificationConsolidated.justificationDetails.find(y => y.rJustificationReason_Id === x.id);
        return {
          ...x,
          justification_Id: this.selectedItem.id,
          rJustificationReason_Id: matchedReason ? matchedReason.rJustificationReason_Id : x.id,
          justificationPercent: matchedReason ? matchedReason.justificationPercent : null
        };
      });
    }
    else {
      this.reasonProcessedList = this.reasonList;
    }
  }

  getTotalPercentage() {
    let sum = 0;
    this.reasonProcessedList.forEach(item => {
      if (item.justificationPercent !== null && !isNaN(item.justificationPercent)) {
        sum += item.justificationPercent;
      }
    });
    return sum;
  }

  getJustificationReasons() {
    this.spinnerService.show();
    this.justificationReasonService.getJustificationReasons(this.appSession.user.id).subscribe(
      (data: any) => {
        this.spinnerService.hide();
        this.reasonList = data.sort((a, b) => a.sequence - b.sequence);
      }, (error: any) => {

        this.spinnerService.hide();
        abp.log.error(error);
        abp.notify.error("", "Failed to load justifications", { showCloseButton: true, timer: 10000 });
      }
    )
  }

  save(): void {
    this.submitted = true;
    // if (!this.justificationForm.valid) return;
    this.spinnerService.show();
    var currentDate = this.dateTimeService.formatDate(this.dateTimeService.toUtcDate(new Date()), AppConsts.DateFormat);

    if (this.selectedItem?.id) {
      this.selectedItem.increase = true, //todo
        this.selectedItem.rStandardCategory_Id = this.justificationForm.value.rStandardCategory_Id,
        this.selectedItem.explanation = this.justificationForm.value.explanation,
        this.selectedItem.totalJustificationPercent = this.getTotalPercentage().toString(),
        this.selectedItem.justificationDetails = this.reasonProcessedList;
      this.selectedItem.lastModificationTime = currentDate;
      this.selectedItem.lastModifierUserId = this.appSession.user.id;

    }
    else {
      this.selectedItem = {
        ownerId: this.ownerId,
        increase: true, //todo
        rStandardCategory_Id: this.justificationForm.value.rStandardCategory_Id,
        explanation: this.justificationForm.value.explanation,
        totalJustificationPercent: this.getTotalPercentage().toString(),
        justificationDetails: this.reasonProcessedList,
        lastModifierUserId: this.appSession.user.id,
        sequence: GlobalConstants.defaultSequence,
        creationTime: currentDate,
        creatorUserId: this.appSession.user.id
      }
    }

    var createOrUpdateNCRType = (this.selectedItem.id) ? this.justificationService.updateJustification(this.selectedItem) :
      this.justificationService.createJustificationConsolidated(this.selectedItem);
    createOrUpdateNCRType.subscribe(
      (data: any) => {
        this.spinnerService.hide();
        if (this.selectedItem.id) {
          abp.notify.success("", "Justification updated successfully", { showCloseButton: true, timer: 10000 });
        } else {
          abp.notify.success("", "Justification added successfully", { showCloseButton: true, timer: 10000 });
        }
        this.modalSave.emit();
        this.close();
      }, (error: any) => {

        this.spinnerService.hide();
        abp.log.error(error);
        abp.notify.error("", "Failed to save Justification", { showCloseButton: true, timer: 10000 });
      }
    )

  }

  close(): void {
    this.submitted = false;
    this.showSideBar = false;
    this.clear();
  }

  clear() {
    this.selectedItem = null;
  }

}




