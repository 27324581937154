import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-audits-overdue',
  templateUrl: './widget-audits-overdue.component.html',
  styleUrls: ['./widget-audits-overdue.component.css']
})
export class WidgetAuditsOverdueComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
