import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBaseService } from '../httpbase.service';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private httpBaseService: HttpBaseService) { }

  getAuditsByClientId(clientId, userId): Observable<any> {
    const apiUrl = 'Audit/GetAuditsByClientId/' + clientId + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  getAuditLocationsByAuditId(auditId, userId): Observable<any> {
    const apiUrl = 'AuditLocation/GetAuditLocationsByAuditId/' + auditId + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  getAuditAndClientDetailsByAuditId(auditId, userId): Observable<any> {
    const apiUrl = 'Audit/GetAuditAndClientDetailsByAuditId/' + auditId + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }
}
